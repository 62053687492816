import { html } from 'htm/preact';
import { render, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { signal } from "@preact/signals";
import moment from 'moment';

import {InputOptions, FormControlSelect, InputField, InputTextarea, FieldContainer, FieldColumn, FieldLabel} from '../../components/FormControls.js';

import {Accordion, AccordionGroup} from '../../components/Accordion.js';

import {formatDateMDY} from '../../components/Date.js';

import {AlertSuccess, AlertError, AlertInfo} from '../../components/Alert.js';
import {SubHeading} from '../../components/Heading.js';


import { route, getCurrentUrl } from 'preact-router';

const submitLoader = signal(false);

const errormessage = signal(false);



const previous_license = signal([]);
const stayed_years = signal([]);
const allowAddPreviousLicense = signal(true);
const currentAddDateFrom = signal(null);
const currentAddDateTo = signal(null);

const showAddNewForm = signal(false);

const total_stayed_years = (arr) =>
{
    return arr.reduce((a, b) => a + b, 0);
 
}


const getSumOfDateDiff = (date_arr) =>
{
    let total = 0;
    
    for (var i = 0; i < date_arr.length; i++) {
    	
    	if(date_arr[i].date_from && moment(date_arr[i].date_from).isValid() && date_arr[i].date_to && moment(date_arr[i].date_to).isValid())
    	{
    	    const a = moment(date_arr[i].date_from);
            const b = moment(date_arr[i].date_to);
            const diff = b.diff(a, 'years'); 
            
            total+= diff;
    	}
    }
    return total;
}

const shouldAddPreviousLicense = () => {
    const from = moment(currentAddDateFrom.value);
    const to = moment(currentAddDateTo.value);
    let years = 0;
    if( from.isValid() && to.isValid() )
    {
        //debugger;
        const diff = to.diff(from, 'years');
        const stayed_yearsv = diff;
        years+=diff;
        
       // console.log((diff >= 3));
    }
    
    years += getSumOfDateDiff(previous_license.value);
    
    allowAddPreviousLicense.value = years < 3;
    return true;
}


function addMoreLicense(e)
{
     
    e.preventDefault();
    /*const fields = {employer_name: $("#currentLicenseNumber").val(), supervisor_name: $("#currentEmployerSupervisorName").val(), has_safety_regulations: $("#EmployerHasSafetyReg").find('input:checked').val(), has_drug_testing: $("#EmployerHasDrugTesting").find('input:checked').val(), address: $("#EmployerAddress").val(), phone: $("#EmployerPhone").val() ,date_from: $("#employedFrom").val(), date_to: $("#employedUntil").val(), position: $('#EmployerPostion').val(), no_of_accidents : $('#EmployerNoOfAccidents').val(), states_drive_in : $('#EmployerStateDriveIn').val(), reason_for_leaving: $('#EmployerReasonLeaving').val()};*/
    
    const removeSquareBrackets = (n) => {
            
        return n.match(/\[(.*?)\]/) ? n.match(/\[(.*?)\]/)[1] : '';
    }
    
    let fields = {};
    $('#newlicensefields').find(":input").map(function(el){ if(this.name) {  fields[removeSquareBrackets(this.name)] = this.value; } }).get();
    

    let invalidfields = 0;
    
    for( let x in fields )
    {
        invalidfields += fields[x] === '' ? 1 : 0;
    }
    
    if( invalidfields > 0 ){
        
        errormessage.value = 'You need to enter license information in above.';
        return;
    }
    
    
    previous_license.value = [...previous_license.value, fields];
    
    
    // reset
   
   
   $('#newlicensefields').find(":input").val("");
   
   showAddNewForm.value = false;

}

function updatePrevLiceseFrom(index,change)
{
    
    previous_license.value = previous_license.value.map((addrss, i) => (i === index ? { ...addrss, ...change } : addrss));
}

function removePrevLicense(row) {
    //debugger;
  previous_license.value = previous_license.value.filter(t => t !== row);
  shouldAddPreviousLicense();
}

function DriverLicenseFields(props)
{
    
    
    const getLicenseField = (k) => {
        
        const license = props.data.value && props.data.value.currentlicense ? props.data.value.currentlicense : '';
        if( license && license.hasOwnProperty(k) )
        {
            return license[k];
        }else {
            return '';
        }
    }
    
    const onChange = (e, index) => {
        const extractname = e.target.name.slice(1,-1).split('][')[1];
        updatePrevLiceseFrom(index, { [extractname]: e.target.value});
    }
    
    useEffect( ()=>{ 
        
        console.log("DriverLicenseFields loaded");
        
    }, [])
    
    useEffect( ()=>{
        
        
        if( props.data && props.data.value && props.data.value.licenses )
          {
              previous_license.value = props.data.value.licenses.filter( l => l.is_current != '1' );
              
              console.log("AddressSection", previous_license.value);
          }

    }, [props.data.value] );
    
    const getCurrentLicense = (rows) =>
    {
        const res = rows.filter(r => ( r.is_current == '1' ));
        return res ? res[0] : '';
        
    }
    
    return html `
        <div>
            <${SubHeading} text="CURRENT DRIVER'S LICENSE" />

            
            
            
            <${CurrentLicenseFieldsContainer} currentlicense="${getCurrentLicense(props.data.value.licenses)}" /> 
           
            ${ previous_license.value.length > 0 && html`<${Fragment}><h3 class="mt-5 text-sm font-semibold leading-7 ">Previously Held Licenses</h3> 
                <${PreviousLicensesContainer} license="${previous_license.value}" /></${Fragment}>` }
           
           
           ${ !showAddNewForm.value && allowAddPreviousLicense.value && shouldAddPreviousLicense() && html `
                <br />
                
                <div class="text-right">
                <a href="javascript:void(0);" class="px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500" onClick="${() => (showAddNewForm.value= true)}">+ Add Previous License</a>
                </div>
                
            ` }
            
            ${
                
                showAddNewForm.value && html `
                <br />
                <${SubHeading} text="ADD PREVIOUS LICENSE" />
                    <div id="newlicensefields"><${NewLicenseFields} onChange="${()=> ''}" getLicenseField="${(e) => ''}" /></div>
                    <br />
                    <div class="text-right">
                        <a href="javascript:void(0);" class="mr-5" onClick="${() => (showAddNewForm.value= false)}"><i class="fa fa-times" ></i> Cancel</a>
                        <a href="javascript:void(0);" class="px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500" onClick="${addMoreLicense}">+ Add</a>
                    </div>
                `
            }
            
        </div>
    `;
}

function PreviousLicensesContainer({license})
{
    const onChange = (e, index) => {
        //debugger;
        const extractname = e.target.name.slice(1,-1).split('][')[1];
        updatePrevLiceseFrom(index, { [extractname]: e.target.value});
    }
    
    return html `<div class="bg-[#f3f3f3]  rounded-md border-black border-5 shadow-1">
                    <${AccordionGroup}>
                    ${license.map( (row ,index, arr) => ( html `
                    <${Accordion} onRemove="${ (e) => removePrevLicense(row) }" first="${index === 0}" last="${index === arr.length - 1}" heading="${row.license_number + ' - ' + formatDateMDY(row.expiration_date)}">
                            <${PreviousLicenseRow} row="${row}" index="${index}"  onChange="${onChange}" getLicenseField="${(k) => row && row.hasOwnProperty(k) ? row[k] : ''}" />
                            
                        </${Accordion}>`) ) }
                    </${AccordionGroup}>
                    </div>`;
}

function PreviousLicenseRow({index, row, onChange })
{
    /*const onChange = (e, index) => {
        const extractname = e.target.name.slice(1,-1).split('][')[1];
        updatePrevLiceseFrom(index, { [extractname]: e.target.value});
    }*/
    
    const getStates = (t) => (t.context.store.getState().states); 

    
    return html `
        <${FieldContainer} columns="4">

        	<${InputField} required value="${row.license_number}" label="License Number"  placeholder="Enter License Number" name="previous_licenses[${index}][license_number]" onChange="${(e) => onChange(e, index)}" />
        

        	<${FormControlSelect} required defaultval="${row.state}" emptyfirstoption="true"  label="State" options="${getStates(this)}"  name="previous_licenses[${index}][state]" />
        
            <${FormControlSelect} required onChange="${(e) => onChange(e, index)}" defaultval="${row.license_type ? row.license_type : (row.type ? row.type :'' )}" wrapselect="true" label="Type" name="previous_licenses[${index}][license_type]"  options="${ [{label: '', value: ''},{label: 'Class A', value: 'Class A'},{label: 'Class B', value: 'Class B'},{label: 'Class C', value: 'Class C'}] }" style='width:100%;' />
        
        
            <${InputField} required datepicker="true"  value="${row.expiration_date}" label="Expiration Date"  placeholder="Enter expiration date..." name="previous_licenses[${index}][expiration_date]" onChange="${(e) => onChange(e, index)}" />
        
        </${FieldContainer}>
    `;
}

function NewLicenseFields(props)
{
    
    const getStates = () => (this.context.store.getState().states); 
    useEffect(()=>{
         errormessage.value  = false;
        
    },[]);
    
    return html `
    <div>
        <${FieldContainer} columns="4">

        	<${InputField} value="" label="License Number"  placeholder="Enter License Number" name="current[license_number]"  />
        
        	<${FormControlSelect} wrapselect="true" emptyfirstoption="true"  label="State" options="${getStates()}"   name="current[state]" />
        	
        
            <${FormControlSelect} wrapselect="true" required defaultval="" wrapselect="true" label="Type" name="current[license_type]"  options="${ [{label: '', value: ''},{label: 'Class A', value: 'Class A'},{label: 'Class B', value: 'Class B'},{label: 'Class C', value: 'Class C'}] }" style='width:100%;' />
        
        
            <${InputField} datepicker="true"  value="" label="Expiration Date"  placeholder="Enter expiration date..." name="current[expiration_date]" />
        
        </${FieldContainer}>
            ${ errormessage.value && html `<${AlertError} msg="${errormessage.value}" />` }
        </div>
    `;
}

function CurrentLicenseFieldsContainer({currentlicense})
{

	const getLicenseField = (k) => {
        
        const license = currentlicense;
        if( license && license.hasOwnProperty(k) )
        {
            return license[k];
        }else {
            return '';
        }
    }
    
    useEffect( () => {
        
            console.log("currentlicense has changed", currentlicense);
        
    }, [currentlicense] );
    
	return html`
	 <div class="bg-[#f3f3f3]  rounded-md border-black border-5 shadow-1">
   <${AccordionGroup}>
        <${Accordion} first="${true}" last="${true}" heading="${getLicenseField('license_number') + ' ' + formatDateMDY(getLicenseField('expiration_date'))}">
            <${CurrentLicenseFields} data="${currentlicense}"  getLicenseField="${getLicenseField}" />
        </${Accordion}>
   </${AccordionGroup}>
   </div>
`;
}

function CurrentLicenseFields({ data })
{
    const getStates = (t) => (t.context.store.getState().states); 

    
    const getLicenseField = (k) => {
        
        const license = data;
        if( license && license.hasOwnProperty(k) )
        {
            return license[k];
        }else {
            return '';
        }
    }
    
    const onChange = (e, index) => {
        const extractname = e.target.name.slice(1,-1).split('][')[1];
        //updatePrevLiceseFrom(index, { [extractname]: e.target.value});
    }
    
    return html `
        <${FieldContainer} columns="4"> 

        	<${InputField} value="${getLicenseField('license_number')}" label="License Number"  placeholder="Enter License Number" name="current[license_number]" onChange="${onChange}" />
        

        	<${FormControlSelect} emptyfirstoption="true"  label="State" options="${getStates(this)}" defaultval="${getLicenseField('state')}"  name="current[state]" onChange="${onChange}" />
        
            <${FormControlSelect} required defaultval="${getLicenseField('type')}" wrapselect="true" label="Type" name="current[license_type]"  options="${ [{label: '', value: ''},{label: 'Class A', value: 'Class A'},{label: 'Class B', value: 'Class B'},{label: 'Class C', value: 'Class C'}] }" style='width:100%;' />
        
        
            <${InputField} datepicker="true"  value="${getLicenseField('expiration_date')}" label="Expiration Date"  placeholder="Enter expiration date..." name="current[expiration_date]" onChange="${onChange}" />
        
        </${FieldContainer}>
    `;
}

function Form({data}){
    return html `
    <div>
         <div class="py-3"><${AlertInfo} msg="List all drivers license/permits held in the past 3 years" /></div>
        <${DriverLicenseFields} data="${data}" />
    </div>
    
    `;
}

function FormContainer({ currentStep, data, _validate })
{
    const f_ref = useRef(null);
    const onSubmit = (e) => {
        e.preventDefault();
        
        
        submitLoader.value = true;
        const formdata = new FormData(e.target);
        const url = '/applylicense/save'; 
        fetch(url, {
            method: 'post',
            body: formdata
        }).then(r => r.json()).then(r => {
            if( r.success )
            {
                //alert('sucess');
                _validate(4);
                route(r.redirect);
                
               // location = r.redirect;
            }else {
                alert('error');
            }
            submitLoader.value = false;
        }).catch(e => {
            alert('error');
            submitLoader.value = false;
        });
    };
    
    const _prev = () => {
        const backstepto = '2';
        const path = getCurrentUrl().split('/'); 
        path[path.length-1] = backstepto;
        route(path.join('/'));
        
        //route(r.redirect);
    }
    
    useEffect( () => {
        /*$.validator.setDefaults({
            errorClass: "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400",
            wrapper: "p",
    		submitHandler: function(form, event) {
    			onSubmit(event);
    		}
    	});*/
        //$( f_ref.current ).validate();
        
    }, [] );
    
    return html `
    <div>
         <form method="post" onSubmit="${onSubmit}" ref="${f_ref}" class="text-left" >
            <input type="hidden" name="_token" value="${csrf_token}" />
            <input type="hidden" name="driver_id" value="${data.value && data.value.driver_id}" />
            <${Form} data="${data}" />
            <div class="flex items-center justify-between mt-8">
                 <button type="button" onClick="${(e) => _prev()}" class="flex items-center text-gray-700 text-sm font-medium rounded hover:underline focus:outline-none">
                    <svg class="h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
                    <span class="mx-2">Back</span>
                </button>
                <button type="submit" class="flex items-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 ${submitLoader.value ? 'disabled' : ''}">${submitLoader.value ? html `<${Fragment}><svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg> Processing </${Fragment}>` : html`<${Fragment}><span>Save & Continue</span>
<svg class="h-5 w-5 mx-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg></${Fragment}>`}</button>
            </div>
         </form>

    </div>`;
}


export default function DriverLicenseInformation({ data, currentStep, afterValid })
{
    const _validate =(s) => {
        
       // step1progress.value = 20;
        
    	afterValid(s);
  	}
  	
    return html `<${FormContainer} currentStep="${currentStep}" data="${data}" _validate="${_validate}" />`;
} 

