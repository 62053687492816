import { html } from 'htm/preact';
import { render, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { signal, useSignal } from "@preact/signals";
import moment from 'moment';

import {InputOptions, FormControlSelect, InputMonthYear, InputField, InputTextarea, FieldContainer, FieldColumn, FieldLabel} from '../../components/FormControls.js';
import {Accordion, AccordionGroup} from '../../components/Accordion.js';

import {AlertSuccess, AlertError, AlertInfo} from '../../components/Alert.js';
import {SubHeading} from '../../components/Heading.js';


import { route, getCurrentUrl } from 'preact-router';

const submitLoader = signal(false);

const errormessage = signal(false);

const getCountryStateName = (arr, str) => {
	let res = arr.find(o => o.value == str);
	return res ? res.label : '';
}

const previous_traffic_conv = signal([]);
const allowAddPreviousTraffic = signal(true);
const shouldAddPreviousTraffic = () => {
    
    return true;
}
const showAddNewForm = signal(false);

function addMoreTrafficeConv(e, errormessage)
{
     
    e.preventDefault();
    
    
    const removeSquareBrackets = (n) => {
            
        return n.match(/\[(.*?)\]/) ? n.match(/\[(.*?)\]/)[1] : '';
    }
    
    let fields = {};
    $('#newtrafficfields').find(":input").map(function(el){ if(this.name) {  fields[(this.name)] = this.value; } }).get();
    

    let invalidfields = 0;
    
    for( let x in fields )
    {
        invalidfields += fields[x] === '' ? 1 : 0;
    }
    
    if( invalidfields > 0 ){
        
        //alert('You need to enter information in above.');
        errormessage.value = 'You need to enter information in above.';
        return;
    }else {
        errormessage.value = false;
    }
    
    
    previous_traffic_conv.value = [...previous_traffic_conv.value, fields];
    
    
    // reset
   
   
   $('#newtrafficfields').find(":input").val("");
   
   showAddNewForm.value = false;

}

function updatePrevTrafConvFrom(index,change)
{
    
    previous_traffic_conv.value = previous_traffic_conv.value.map((addrss, i) => (i === index ? { ...addrss, ...change } : addrss));
}

function removePrevTrafConv(row) {
    //debugger;
  previous_traffic_conv.value = previous_traffic_conv.value.filter(t => t !== row);
  shouldAddPreviousTraffic();
}

    
    
function PreviousTrafficConvRow({ row, index })
{
    const getStates = () => (this.context.store.getState().states); 

    const onChange = (e) => {
        const extractname = e.target.name.slice(1,-1).split('][')[1];
        updatePrevTrafConvFrom(index, { [extractname]: e.target.value});
    }
    return html `<div>
                    <${FieldContainer} columns="4">
                        <${InputMonthYear} value="${row.traffic_date}" type="text" label="Date Convicted" placeholder="Select Month/Year"  name="traffic_convictions[${index}][traffic_date]" onChange="${onChange}" />
                        

                        <${FormControlSelect} emptyfirstoption="true"  label="State Of Violation" defaultval="${row.location}" options="${getStates()}" wrapselect="true" name="traffic_convictions[${index}][location]" onChange="${onChange}" />
                        
                        <${InputField} type="number" name="traffic_convictions[${index}][charge]" value="${row.charge}" label="Violation" placeholder="Enter Charge"  onChange="${onChange}" />
                        
                        <${InputField} value="${row.penalty}" name="traffic_convictions[${index}][penalty]" label="Penalty" placeholder="eg: Forfeited bond, collateral and/or points"  onChange="${onChange}" />
                    </${FieldContainer}>
                    
                   
                </div>`;
}

function FieldsContainer({ data })
{
    const checked = useSignal(false);
    
    useEffect( ()=>{
        
        if( data )
        {
            //setShow();
           // debugger;
            if( data && data.value && data.value.trafficconvictions && data.value.trafficconvictions.length > 0 )
            {
                  previous_traffic_conv.value = data.value.trafficconvictions;
                 // setShow(true);
                  checked.value = true;
                  console.log("AddressSection", previous_traffic_conv.value);
            }
        }
        
    },  [data])
    
    return html `
        <div>
            <br />
            <h4 class="mb-2 text-left font-bold">
                
                <${InputOptions} type="radio"  label="Have you had any traffic convictions and forfeitures in the past 3 years?" name="has_traffic_c" value="${checked}" onChange="${(e) => checked.value = (e.target.value == '1')}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />
            </h4>
            
             
        ${checked.value && html `<${Fragment}><div class="py-3"><${AlertInfo} msg="Add all the vehicle moving traffic convictions and forfeitures for the past 3 years" /></div> <${Fields}  data="${data}" display="" /> </${Fragment}>`}
        
        </div>
    
    `;
}

function Fields({ display= false })
{

    
    
    const getStates = () => (this.context.store.getState().states); 

    
    useEffect( ()=>{
        
       console.log("Fields renders");

    }, [previous_traffic_conv] );
    
    return html `
        
        <div>
            <div id="newtrafficfields">
                <${FieldContainer} columns="4">
                    <${InputMonthYear} type="text" label="Date Convicted" placeholder="Select Month/Year" name="traffic_date" onChange="${()=> console.log('test')}" />
                    

                    
                    <${FormControlSelect} wrapselect="true" emptyfirstoption="true"  label="State Of Violation" options="${getStates()}" name="location" />
                    
                    <${InputField} type="number" label="Violation" placeholder="Enter Charge" name="charge" onChange="${()=> console.log('test')}" />
                    
                    <${InputField} label="Penalty" placeholder="eg: Forfeited bond, collateral and/or points" name="penalty" onChange="${()=> console.log('test')}" />
                </${FieldContainer}>
            </div>
            
            ${ errormessage.value && html `<${AlertError} msg="${errormessage.value}" /> <br />` }
            
            ${ allowAddPreviousTraffic.value && shouldAddPreviousTraffic() && html `                    
                <div class="text-right">
                <a href="javascript:void(0);" class="px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 " onClick="${(e) => addMoreTrafficeConv(e, errormessage)}">+ Add</a>
                </div>
                
            ` }
            
            ${ previous_traffic_conv.value.length > 0 && html`<${Fragment}><${SubHeading} text="PREVIOUS RECORD" />

            <div class="bg-[#f3f3f3]  rounded-md border-black border-5 shadow-1">
                <${AccordionGroup}>
                ${previous_traffic_conv.value.map( (row ,index, arr) => ( html ` 
                <${Accordion} onRemove="${ (e) => removePrevTrafConv(row) }" first="${index === 0}" last="${index === arr.length - 1}" heading="${ getCountryStateName(getStates(), row.location) + ' ' + row.penalty+ ' ' +row.traffic_date  }">
                        <${PreviousTrafficConvRow} row="${row}" index="${index}" />
                    </${Accordion}>`) ) }
                </${AccordionGroup}>
                </div></${Fragment}>` }
            
           
        </div>
    `;
}

function Form({data}){
    return html `
    <div>
         
        <${FieldsContainer} data="${data}" />
    </div>
    
    `;
}

function FormContainer({ currentStep, data, _validate })
{
    const f_ref = useRef(null);
    const onSubmit = (e) => {
        e.preventDefault();
        
         errormessage.value =false;
        const handleValidation = () => {
            const a = $('input[name="has_traffic_c"]:checked').val() == '1';
        let formIsValid = a ? previous_traffic_conv.value.length > 0 : true;
        
        return formIsValid;
    }
    
        
    
        if( !handleValidation() )
        {
            errormessage.value = 'Click "Add" button before clicking "Save & Continue" button';
            //alert('traffic convictions information required!');
            return false;
        }
        
        submitLoader.value = true;
        const formdata = new FormData(e.target);
        const url = '/applytrafficconivtions/save'; 
        fetch(url, {
            method: 'post',
            body: formdata
        }).then(r => r.json()).then(r => {
            if( r.success )
            {
                //alert('sucess');
                _validate(5);
                route(r.redirect);
                
               // location = r.redirect;
            }else {
                alert('error');
            }
            submitLoader.value = false;
        }).catch(e => {
            alert('error');
            submitLoader.value = false;
        });
    };
    
    const _prev = () => {
       const backstepto = '3';
        const path = getCurrentUrl().split('/'); 
        path[path.length-1] = backstepto;
        route(path.join('/'));
    }
    
    useEffect( () => {
        /*$.validator.setDefaults({
            errorClass: "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400",
            wrapper: "p",
    		submitHandler: function(form, event) {
    			onSubmit(event);
    		}
    	});*/
        //$( f_ref.current ).validate();
        
    }, [] );
    
    return html `
    <div>
         <form method="post" onSubmit="${onSubmit}" ref="${f_ref}" class="text-left" >
            <input type="hidden" name="_token" value="${csrf_token}" />
            <input type="hidden" name="driver_id" value="${data.value && data.value.driver_id}" />
            <${Form} data="${data}" />
            <div class="flex items-center justify-between mt-8">
                 <button type="button" onClick="${(e) => _prev()}" class="flex items-center text-gray-700 text-sm font-medium rounded hover:underline focus:outline-none">
                    <svg class="h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
                    <span class="mx-2">Back</span>
                </button>
                <button type="submit" class="flex items-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 ${submitLoader.value ? 'disabled' : ''}">${submitLoader.value ? html `<${Fragment}><svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg> Processing </${Fragment}>` : html`<${Fragment}><span>Save & Continue</span>
<svg class="h-5 w-5 mx-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg></${Fragment}>`}</button>
            </div>
         </form>

    </div>`;
}


export default function DriverTrafficConvictions({ data, currentStep, afterValid })
{
    const _validate =(s) => {
        
       // step1progress.value = 20;
        
    	afterValid(s);
  	}
  	
    return html `<${FormContainer} currentStep="${currentStep}" data="${data}" _validate="${_validate}" />`;
} 

