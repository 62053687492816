import { html } from 'htm/preact';
export default function footer(props) {
	


	return html`
    <footer class="bg-white pb-3 pt-10 lg:pt-20 border-t">

      <div class="flex flex-wrap justify-around gap-y-16 gap-x-7 mb-16">

        <!-- About -->
        <div class="text-center mx-2 mb-5">
          <h2 class="font-bold text-2xl mb-3 tracking-wider text-center"><img src="./img/inlet-logo.png" style="width:100%;" class="align-center" alt="logo" /></h2>
          <p class="">
              <address class="">
                  Inlet Trucking LLC.<br />
                  112 Midsouth Drive<br />
                  Judsonia, AR 72081
              </address>
          </p>

          <!-- Get Icons From Wherever and use it here -->
          <div class="flex flex-wrap gap-x-7 justify-center gap-y-5 mt-5">
            <a href="#!" class="text-primary-color hover:opacity-90">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
</svg> 
            </a>
            <a href="#!" class="text-primary-color hover:opacity-90">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
</svg>
 
            </a>
            <a href="#!" class="text-primary-color hover:opacity-90">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
</svg>

            </a>
          </div>
        </div>

        <div class="flex flex-wrap gap-y-8 sm:grid grid-cols-2">
        <!-- Heading -->
        <div class="mx-10 lg:mx-16">
          <h2 class="font-bold text-xl mb-4">Heading</h2>
          <ul class="flex flex-col items-start gap-y-2 text-left font-semibold text-gray-400">
            <li><a href="#!" class="hover:text-primary-color">link</a></li>
            <li><a href="#!" class="hover:text-primary-color">link</a></li>
            <li><a href="#!" class="hover:text-primary-color">link</a></li>
            <li><a href="#!" class="hover:text-primary-color">link</a></li>
          </ul>
        </div>

        <!-- Heading 2 -->
        <div class="mx-10 lg:mx-16">
          <h2 class="font-bold text-xl mb-4">Heading 2</h2>
          <ul class="flex flex-col items-start gap-y-2 text-left font-semibold text-gray-400">
            <li><a href="#!" class="hover:text-primary-color">link</a></li>
            <li><a href="#!" class="hover:text-primary-color">link</a></li>
            <li><a href="#!" class="hover:text-primary-color">link</a></li>
            <li><a href="#!" class="hover:text-primary-color">link</a></li>
          </ul>
        </div>

        </div>

        <!-- Resources (Delete This if your website does not support newsletter) -->
        <div class="mx-10 lg:mx-16">
          <h2 class="font-bold text-xl mb-4">Resources</h2>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tenetur.</p>

          <form action="" class="mt-5 flex">
            <input type="email" name="email"
              class="bg-slate-800 outline-0 py-2 px-3 rounded-tl rounded-bl w-full caret-primary-color"
              placeholder="Enter email here..." />
            <button type="submit"
              class="bg-primary-color px-2 font-semibold text-slate-900 rounded-tr rounded-br hover:opacity-90">Subscribe</button>

          </form>
        </div>
        <!-- Resources ends here -->

      </div>

      
      <!-- Sub Footer -->
      <div class="h-1 w-full bg-gradient-to-r from-primary-color to-green-500 "></div>
      <!-- Copyright -->
      <p class="text-center mt-8 mb-4 font-semibold">INLET TRUCKING LLC - Copyright &copy; <span id="year">2024</span>. All
        Rights
        Reserved</p>

    </footer>
	
	`;
}