import { html } from 'htm/preact';
import footer from './footer.js';
import topnav from './topnav.js';
import { Fragment, toChildArray } from 'preact';


export default function master(props) {
    return html `
        <${Fragment}>
        <${topnav} />
        <main>
        <${html `${(props.children)}`} />
        <${footer} />
        </main>
        </${Fragment}>
    `;
    
    
}