import { html } from 'htm/preact';
import { render, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { signal, useSignal  } from "@preact/signals";
import moment from 'moment';

import {InputOptions, FormControlSelect, InputField, InputTextarea, FieldContainer, FieldColumn, FieldLabel, InputOptionsList} from '../../components/FormControls.js';
import {formatDateMDY} from '../../components/Date.js';


import {SignModalComponent, openSignModal, signformsubmit} from '../../components/SignModal.js';

import {Accordion, AccordionGroup} from '../../components/Accordion.js';

import {AlertSuccess, AlertError, AlertInfo} from '../../components/Alert.js';
import {SubHeading} from '../../components/Heading.js';

import { Scrollbars } from 'preact-custom-scrollbars';

import { route, getCurrentUrl } from 'preact-router';

const submitLoader = signal(false);


const errorExisting  = signal(false);


const previous_road_accidents = signal([]);
const allowAddRoadAccident = signal(true);
const shouldAddRoadAccident = () => {
    
    return true;
}

function addMoreRoadAccident(e, error)
{
     
    e.preventDefault();
    
    
    const removeSquareBrackets = (n) => {
            return n;
        return n.match(/\[(.*?)\]/) ? n.match(/\[(.*?)\]/)[1] : '';
    }
    
    let fields = {};
    $('#newroadAccidentfields').find(":input").not(':radio').map(function(el){ if(this.name) {
            fields[removeSquareBrackets(this.name)] = this.value; 
        
    }
        
    }).get();
        
        fields['is_preventable'] = $('#newroadAccidentfields').find('input[name="is_preventable"]:checked').val();
        fields['has_fatalities'] = $('#newroadAccidentfields').find('input[name="has_fatalities"]:checked').val();
        fields['has_injuries'] = $('#newroadAccidentfields').find('input[name="has_injuries"]:checked').val();

    let invalidfields = 0;
    
    for( let x in fields )
    {
        invalidfields += fields[x] === '' || typeof fields[x] == 'undefined' ? 1 : 0;
    }
    
    if( invalidfields > 0 ){
        
        error.value = 'You need to enter accident information in above.';
        //alert('');
        return;
    }else {
        error.value = false;
    }
    
    
    previous_road_accidents.value = [...previous_road_accidents.value, fields];
    
    
    // reset
   
   
   $('#newroadAccidentfields').find(":input").val("");
   

}

function updateRoadAccidentRow(index,change)
{
    
    previous_road_accidents.value = previous_road_accidents.value.map((addrss, i) => (i === index ? { ...addrss, ...change } : addrss));
}

function _removeRoadAccident(row) {
    //debugger;
  previous_road_accidents.value = previous_road_accidents.value.filter(t => t !== row);
  shouldAddRoadAccident();
}



const nature_extent_qa_checked = signal(0);
const any_nature_extent_j_checked = signal(0);


const onChangeNat = (e) => {
    const total = $(e.target).closest('.nature_extent_sub').find('input[value="1"]:checked').length;
    nature_extent_qa_checked.value = total;
    
}


function PreviousRoadAccidentRow({ row, index })
{
    
     const onChange = (e) => {
        const extractname = e.target.name.slice(1,-1).split('][')[1];
        updateRoadAccidentRow(index, { [extractname]: e.target.value});
    }
    return html `
        <${FieldContainer} columns="3">
        
                <${FieldContainer}  columns="2" mt="0">
                    <${InputField} type="text" value="${row.accident_date}" datepicker="true" label="Date" placeholder="Enter date" name="roadaccidents[${index}][accident_date]" onChange="${onChange}" />
                    <${InputField} type="number" value="${row.amount_damage}" label="Damage ($)" placeholder="Amount Of Damage"  name="roadaccidents[${index}][amount_damage]"  onChange="${onChange}" />
                </${FieldContainer}>
                
                <${InputField} value="${row.type_of_vehicle}" label="Type of vehicle" placeholder="Enter Type of vehicle" name="roadaccidents[${index}][type_of_vehicle]" onChange="${onChange}" />
                
                <${InputField} value="${row.accident_nature}" label="Nature of accident" placeholder="eg: (head on, rear end, etc)"  name="roadaccidents[${index}][accident_nature]" onChange="${onChange}" />
                 
                 
            </${FieldContainer}>
            
            
            <${FieldContainer} columns="4">
               
                
                <${InputOptionsList} value="${row.is_preventable}" inlinestyle="false" type="radio" label="Preventable?"  name="roadaccidents[${index}][is_preventable]" onChange="${onChange}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />
                
                <${InputOptionsList} value="${row.has_fatalities}" inlinestyle="false" type="radio" label="Fatalities?" name="roadaccidents[${index}][has_fatalities]" onChange="${onChange}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />
                
                <${InputOptionsList} inlinestyle="false" value="${row.has_injuries}" type="radio" label="Injuries?"  name="roadaccidents[${index}][has_injuries]"  onChange="${onChange}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />
                
                 
                
            </${FieldContainer}>
    `;
}

function RoadAccidentFields({data})
{
    const errorNew = useSignal(false);
    return html ` 
        <div>
             <${SubHeading} text="ADD ACCIDENT/INCIDENT" />
           <${AlertInfo} msg="List accidents/incidents with vehicles for the past 3 years, including all preventable and non-preventable whether or not on MVR" />
            
            <div id="newroadAccidentfields">
            <${FieldContainer} columns="3">
            
                <${FieldContainer} columns="2" mt="0">
                    <${InputField} type="text" datepicker="true" label="Date" placeholder="Enter date" name="accident_date" onChange="${()=> console.log('test')}" />
                    <${InputField} type="number" label="Amount of damage" placeholder="" name="amount_damage" onChange="${()=> console.log('test')}" />
                </${FieldContainer}>
                 
                <${InputField}  label="Type of vehicle" placeholder="Enter Type of vehicle" name="type_of_vehicle" onChange="${()=> console.log('test')}" />
                
                <${InputField} label="Nature of accident (head on, rear end, etc)" placeholder="" name="accident_nature" onChange="${()=> console.log('test')}" />
                 
                 
            </${FieldContainer}>
            
            
            <${FieldContainer} columns="4">
               
                
                <${InputOptionsList} inlinestyle="false" type="radio" label="Preventable?" name="is_preventable" onChange="${() => console.log(1234)}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />
                
                <${InputOptionsList} inlinestyle="false" type="radio" label="Fatalities?" name="has_fatalities" onChange="${() => console.log(1234)}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />
                
                <${InputOptionsList} inlinestyle="false" type="radio" label="Injuries?" name="has_injuries" onChange="${() => console.log(1234)}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />
                
                 
                
            </${FieldContainer}>
            </div>
            

            <${AccidentInfoRequired} msg="${errorNew}" />
            
            <div class="text-right">
                ${ allowAddRoadAccident.value && shouldAddRoadAccident() && html `                    
            
                    <a href="javascript:void(0);" data-native class="px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 " onClick="${(e) => addMoreRoadAccident(e, errorNew)}">+ Add </a>
                    
                ` }
            </div>
            
           <${PreviousRecordsSection} rows="${previous_road_accidents}" title="PREVIOUS RECORD" />
            
            
         </div>
    `;
}
function AccidentInfoRequired({msg})
{
    return msg.value ?  html `<${AlertError} msg="${msg.value}" />` : '';
}

function PreviousRecordsSection({rows, title})
{
    
    
    
    return  rows.value.length > 0 ? html`<${Fragment}> <${SubHeading} text="${title}" />
                <${PreviousRecordList} rows="${rows}" />
            </${Fragment}>` : '';
}

function PreviousRecordList({rows})
{
    
    
    return html `<div class="bg-[#f3f3f3]  rounded-md border-black border-5 shadow-1">
                <${AccordionGroup}>
                    <${PrevRecordsRender} rows="${rows.value}" />
                </${AccordionGroup}>
                </div>`;
}

function PrevRecordsRender({rows})
{
    const removeRoadAccident =  (e)  =>{
        
        _removeRoadAccident(e);
    }
    
    return rows.map( (row ,index, arr) => ( html ` 
                <${Accordion} onRemove="${ (e) => removeRoadAccident(row) }" first="${index === 0}" last="${index === arr.length - 1}" heading="${ formatDateMDY(row.accident_date) + ' ' + row.accident_nature+ ' ' +row.amount_damage  }">
                        <${PreviousRoadAccidentRow} row="${row}" index="${index}" />
                    </${Accordion}>`) );
}

function Fields({data})
{
    const [show, setShow] = useState(false);
    
    useEffect( ()=>{
        /*debugger;*/
            // prefill address section
          if( data && data.value && data.value.accidents )
          {
              previous_road_accidents.value = data.value.accidents;
              
              console.log("AddressSection", previous_road_accidents.value);
          }
            setShow(previous_road_accidents.value.length >= 1);
        
    }, [] );
    
    useEffect(()=>{
        
       
        
    }, [show]);
    
    return html ` 
        <div>
             <div class="mt-8 mb-2 text-left font-bold alllebelnowwrap"> <${InputOptions} value="${show  ? '1' : '0'}" type="radio" label="Have you had any accidents/incidents with vehicles for the past 3 years, including all preventable and non-preventable whether or not on MVR in the past 3 years?" name="had_accident" onChange="${(e) => setShow(e.target.value == '1')}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  /></div>
                <${RoadAccidentFieldsContainer} data="${data}" show="${show}" />
            
            ${errorExisting.value && html`<br /><${AlertError} msg="${errorExisting.value}" />` }
            
        </div>
    `;
}

function RoadAccidentFieldsContainer({show, data})
{
    return html `${show && html `<${RoadAccidentFields} data="${data}" />`  }`;
}

function Form({ data }){
    

    const getFormSectionField = (k) => {
        
        const info = data.value;
        if( info && info.hasOwnProperty(k) )
        {
            return info[k];
        }else {
            return '';
        }
    }
    
     useEffect( ()=>{
        
      
        
    }, [] );
    
    return html `
    <div>
         
        <${Fields} data="${data}" />
       
    </div>
    
    `;
}

function FormContainer({ currentStep, data, _validate })
{
    const f_ref = useRef(null);
    const onSubmit = (e) => {
        e.preventDefault();
        
        const handleValidation = () => {
            const hadaccident = $('input[name="had_accident"]:checked').val() == '1';
            let formIsValid = true;
            
            if(hadaccident)
            {
                formIsValid = previous_road_accidents.value.length == 0 ? false : true;
            }
        
            return formIsValid;
        }
        
        if( !handleValidation() )
        {
            errorExisting.value = ('accident information required!');
            return false;
        }else {
            errorExisting.value = '';
        }
        
        
        submitLoader.value = true;
        const formdata = new FormData(e.target);
        const url = '/applyroadaccident/save'; 
        fetch(url, {
            method: 'post',
            body: formdata
        }).then(r => r.json()).then(r => {
            if( r.success )
            {
                //alert('sucess');
                _validate(6);
                route(r.redirect);
                
               // location = r.redirect;
            }else {
                alert('error');
            }
            submitLoader.value = false;
        }).catch(e => {
            alert('error');
            submitLoader.value = false;
        });
    };
    
    const _prev = () => {
        const backstepto = '4';
        const path = getCurrentUrl().split('/'); 
        path[path.length-1] = backstepto;
        route(path.join('/'));
        
    }
    
    useEffect( () => {
        /*$.validator.setDefaults({
            errorClass: "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400",
            wrapper: "p",
    		submitHandler: function(form, event) {
    			onSubmit(event);
    		}
    	});*/
        //$( f_ref.current ).validate();
        
    }, [] );
    
    return html `
    <div>
         <form method="post" onSubmit="${onSubmit}" ref="${f_ref}" class="text-left" >
            <input type="hidden" name="_token" value="${csrf_token}" />
            <input type="hidden" name="driver_id" value="${data.value && data.value.driver_id}" />
            <${Form} data="${data}" />
            <div class="flex justify-between items-center mt-8">
            <button type="button" onClick="${(e) => _prev()}" class="flex items-center text-gray-700 text-sm font-medium rounded hover:underline focus:outline-none">
                    <svg class="h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
                    <span class="mx-2">Back</span>
                </button>
            <button type="submit" class="flex items-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 ${submitLoader.value ? 'disabled' : ''}">${submitLoader.value ? html `<${Fragment}><svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> Processing </${Fragment}>` : html`<${Fragment}><span>Save & Continue</span>
            <svg class="h-5 w-5 mx-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg></${Fragment}>`}</button>
                    </div>
         </form>
         <${SignModalComponent} />

    </div>`;
}

function RegulationSection(props)
{
    return html`
        <h3 class="mb-2 text-center font-bold">TO BE READ AND e-SIGNED BY APPLICANT/DRIVER</h3>
        <${Scrollbars} style=${{  height: 300 }}>
    

        <p>This certifies that I completed this application and all entries and information is true and correct to the best of my knowledge.</p>
<p>I authorize this company to make such investigations and inquiries of my personal, employment, financial or medical history and other related matters as may be necessary in arriving at an employment decision. (Generally, inquiries regarding medical history will be made only if and after a conditional offer of employment has been extended.) I hereby release employers, schools, health care providers and other persons from all liability in responding to inquiries and releasing information in connection with my application.</p>
<p>In the event of employment, I understand that false of misleading information given in my application or interviews may result in discharge. I understand, also, that I am required to abide by all rules and regulations of the Company.</p>
<p>I understand that information I provide regarding current and/or previous employers may be used, and those employer(s) will be contacted, for the purpose of investigation my safety performance history as required by 49 CFR 391.23(d) and (e). I understand that I have the right to:</p>
<p>► Review information provided by previous employers</p>
<p>► Have errors in the information corrected by previous employers and for those previous employers to re-send the</p>
<p>corrected information to the prospective employer; and</p>
<p>► Have a rebuttal statement attached to the alleged erroneous information, if the previous employer(s) and I cannot agree on the accuracy of the information.</p>
<p>I understand that I am required to abide by all rules of this company, laws of the states, and regulations of the FMCSA.</p>
</${Scrollbars}>
    `;
}



export default function DriverAccidentRecord({ data, currentStep, afterValid })
{
    const _validate =(s) => {
        
       // step1progress.value = 20;
        
    	afterValid(s);
  	}
  	
    return html `<${FormContainer} currentStep="${currentStep}" data="${data}" _validate="${_validate}" />`;
} 

