import 'preact/devtools';
import { html } from 'htm/preact';
import { render } from 'preact';
import Router from 'preact-router';
import Home from './pages/home.js';
import apply from './pages/apply.js';
import thankyou from './pages/thankyou.js'
import ApplyScreening from './pages/ApplyScreening.js';

import Redirect from './pages/Redirect.js';
import NotFound from './pages/NotFound.js';
import { Provider } from "redux-zero/preact"; 
import store from './store/index.js';   


function App() {
    
    const onChange = (e,ab,c) => {
        console.log(e,ab,c);
    }

	return html`
	 <${Provider} store="${store}">
		<${Router} onChange="${onChange}">
		 	<${Home} path="/232asdsadsa" /> 
		 	<${apply} path="/apply/:uid/:step" />
		 	<${thankyou} path="/thankyou" heading="Success!" details="${html `<p class='text-gray-600 my-2'>Thank you for submitting the application on Smiles Of Miles website!</p><p> We will get back to you after reviewing the information.</p> <p><a href="https://mostrucking.us/">www.mostrucking.us/</a></p>`}" redirectlink='/' />
		 	<${ApplyScreening} path="/applyscreening" /> 
		 	
		</${Router}>
	</${Provider}>
	`;
}

render(html`<${App} />`, document.getElementById('app_spa'));