import { html } from 'htm/preact';
import { useId, useRef, useState,useEffect  } from 'preact/hooks';
import { Fragment, toChildArray } from 'preact';



export default function MultiSelectDropdown({
  formFieldName,
  options,
  hideClearSelection=true,
  hideSelectAll=true,
  onChange = () => {},
  prompt = "Select one or more options",
}) {
  const [isJsEnabled, setIsJsEnabled] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const optionsListRef = useRef(null);
  const refToggleChkbox = useRef(null);

  useEffect(() => {
    setIsJsEnabled(true);
  }, []);

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    const option = e.target.value;

    const selectedOptionSet = new Set(selectedOptions);

    if (isChecked) {
      selectedOptionSet.add(option);
    } else {
      selectedOptionSet.delete(option);
    }

    const newSelectedOptions = Array.from(selectedOptionSet);

    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const isSelectAllEnabled = selectedOptions.length < options.length;

  const handleSelectAllClick = (e) => {
    e.preventDefault();

    const optionsInputs = optionsListRef.current.querySelectorAll("input");
    optionsInputs.forEach((input) => {
      input.checked = true;
    });

    setSelectedOptions([...options]);
    onChange([...options]);
  };

  const isClearSelectionEnabled = selectedOptions.length > 0;

  const handleClearSelectionClick = (e) => {
    e.preventDefault();

    const optionsInputs = optionsListRef.current.querySelectorAll("input");
    optionsInputs.forEach((input) => {
      input.checked = false;
    });

    setSelectedOptions([]);
    onChange([]);
  };
  
  const onFocusOut = (e) => {
      
      if ( $(e.target).parents(refToggleChkbox.current).length ) { 
          
          
      }else {
          
      }
       $(refToggleChkbox.current).prop('checked', false);
     
  }

  return html `
    <label className="relative" onBlur="${onFocusOut}" tabindex="-1">
      <input type="checkbox" className="hidden peer" ref="${refToggleChkbox}" />

      <div className="cursor-pointer after:content-['▼'] after:text-xs after:ml-1 after:inline-flex after:items-center peer-checked:after:-rotate-180 after:transition-transform inline-flex border rounded px-5 py-2">
        ${prompt}
        ${isJsEnabled && selectedOptions.length > 0 && html`
          <span className="ml-1 text-blue-500">${`(${selectedOptions.length} selected)`}</span>
        `}
      </div>
        <div className="absolute bg-white border transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100 peer-checked:pointer-events-auto w-full">
        <input type="text" placeholder="Search here..." />
        </div>
      <div className="absolute bg-white border transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100 peer-checked:pointer-events-auto w-full max-h-60 overflow-y-scroll">
        ${isJsEnabled && (!hideSelectAll || !hideClearSelection) && html `
          <ul>
            ${!hideSelectAll && html `<li>
              <button
                onClick=${handleSelectAllClick}
                disabled=${!isSelectAllEnabled}
                className="w-full text-left px-2 py-1 text-blue-600 disabled:opacity-50"
              >
                ${"Select All"}
              </button>
            </li>`}
            ${ !hideClearSelection && html `<li>
              <button
                onClick=${handleClearSelectionClick}
                disabled=${!isClearSelectionEnabled}
                className="w-full text-left px-2 py-1 text-blue-600 disabled:opacity-50"
              >
                ${"Clear selection"}
              </button>
            </li>` }
            
          </ul>
        `}
        <ul ref=${optionsListRef}>
          ${options.map((option, i) => {
            return html `
              <li key=${option}>
                <label
                  className=${`flex whitespace-nowrap cursor-pointer px-2 py-1 transition-colors hover:bg-blue-100 [&:has(input:checked)]:bg-blue-200`}>
                  <input
                    type="checkbox"
                    name=${formFieldName}
                    value=${option}
                    className="cursor-pointer"
                    onChange=${handleChange}
                  />
                  <span className="ml-1">${option}</span>
                </label>
              </li>
            `;
          })}
        </ul>
      </div>
    </label>
  `;
} 