import { Fragment, toChildArray } from 'preact';
import { html } from 'htm/preact';
import { useState } from 'preact/hooks';


function SubHeading({  text, mb=8 })
{
    return html`<h2 class="text-base font-semibold leading-7  border-b-[2px] mb-${mb} py-0 border-black">${text}</h2>`;
}


export { SubHeading };
