import { html } from 'htm/preact';
import Router from 'preact-router';
import master from '../layout/master.js'; 
import { Fragment } from 'preact';

export default function Home(props) {
	


	return html`<${Fragment}>
		<${master}> 
    <section class="text-center py-32 bg-no-repeat  bg-cover bg-center bg-[url('../../public/img/trcking_banner.png')] text-white h-dvh flex items-center justify-center ">
  <div>
    <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl pb-2" style="background: rgb(0 0 0 / 48%);    padding: 10px;"> Welcome to INLET TRUCKING LLC!</h1>
    <br />
    <h3 class="font-bold text-1xl md:text-2xl lg:text-3xl pb-7" style="background: rgb(0 0 0 / 48%);padding: 10px;display: inline-block;">Join Our Community of Dedicated Truck Drivers</h3>
  </div>
</section>
		</${master}>
	 </${Fragment}>`;
}