import { html } from 'htm/preact';
import { render, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { signal, useSignal } from "@preact/signals";
import moment from 'moment';
import { createPortal } from 'preact/compat';

import {InputOptions, InputInlineOptions, FormControlSelect, InputField, InputTextarea, FieldContainer, FieldColumn, FieldLabel} from '../../components/FormControls.js';
import {Accordion, AccordionGroup} from '../../components/Accordion.js';

import {SignModalComponent, openSignModal, signformsubmit} from '../../components/SignModal.js';


import {AlertSuccess, AlertError, AlertInfo} from '../../components/Alert.js';
import {SubHeading} from '../../components/Heading.js';

import MultiSelectDropdown from '../../components/MultiSelectDropdown.js'
import $ from 'jquery';

import selectize from 'selectize';

import { Scrollbars } from 'preact-custom-scrollbars';

import { route, getCurrentUrl } from 'preact-router';

const submitLoader = signal(false);

const errornew = signal(false);

const errormessage = signal(false);

const previous_nature_exp = signal([]);

const nature_extent_qa_checked = signal(0);
const any_nature_extent_j_checked = signal(0);


const previous_truck_experience = signal([]);
const allowAddPreviousTruckExp = signal(true);
const shouldAddPreviousTruckExp = () => {
    
    return true;
}
const showAddNewForm = signal(false);

const nature_extent_qa2 = signal([
	{
		label: '1. Have you ever had an alcohol test with a result of 0.04 or higher?',
		name: 'has_high_alcohol_test',
		checked: '0',
		options: [
			{label: 'Yes', value: '1'}, {label: 'No', value: '0'}
		]
	},
	{
		label: '2. Have you ever had a verified positive drug test?',
		name: 'has_positive_drug_test',
		checked: '0',
		options: [
			{label: 'Yes', value: '1'}, {label: 'No', value: '0'}
		]
	},
	{
		label: '3. Have you ever refused to be tested?',
		name: 'has_refused_tested',
		checked: '0',
		options: [
			{label: 'Yes', value: '1'}, {label: 'No', value: '0'}
		]
	},
	{
		label: '4. Have you ever violated any DOT drug and alcohol testing regulations?',
		name: 'has_violated_dot_drug_test',
		checked: '0',
		options: [
			{label: 'Yes', value: '1'}, {label: 'No', value: '0'}
		]
	}
]);

const nature_extent_qa1 = signal([
	{
		label: 'A. Have you ever been denied a license, permit, or privilage to operate a motor vehicle?',
		name: 'has_denied_license',
		checked: '0',
		options: [
			{label: 'Yes', value: '1'}, {label: 'No', value: '0'}
		]
	},
	{
		label: 'B. Have you ever had any license, permit, or privilage suspended or revoked?',
		name: 'has_suspended_license',
		checked: '0',
		options: [
			{label: 'Yes', value: '1'}, {label: 'No', value: '0'}
		]
	},
	{
		label: 'C. Have you ever been convicted for driving under influence of alcohol or drugs?',
		name: 'has_convicted_drugs',
		checked: '0',
		options: [
			{label: 'Yes', value: '1'}, {label: 'No', value: '0'}
		]
	},
	{
		label: 'D. Have you ever been convictord for posession, sale, or use of narcotic drug?',
		name: 'has_possesed_drug',
		checked: '0',
		options: [
			{label: 'Yes', value: '1'}, {label: 'No', value: '0'}
		]
	},
	{
		label: 'E. Have you ever been refused liability insurance?',
		name: 'has_refused_insurance',
		checked: '0',
		options: [
			{label: 'Yes', value: '1'}, {label: 'No', value: '0'}
		]
	},
	{
		label: 'F. Have you ever been convicted by a felony?',
		name: 'has_convicted_felony',
		checked: '0',
		options: [
			{label: 'Yes', value: '1'}, {label: 'No', value: '0'}
		]
	},
	{
		label: 'G. Have you ever been convicted of a Misdemeanor?',
		name: 'has_convicted_misdemeanor',
		checked: '0',
		options: [
			{label: 'Yes', value: '1'}, {label: 'No', value: '0'}
		]
	},
	{
		label: 'H. Have you ever been disqualified to drive by Federal Motor Carrier Safety Regulations?',
		name: 'has_disqualified_mcsrf',
		checked: '0',
		options: [
			{label: 'Yes', value: '1'}, {label: 'No', value: '0'}
		]
	}
]);

function addMoreTruckExp(e)
{
    e.preventDefault();
    
    
    const removeSquareBrackets = (n) => {
            
        return n.match(/\[(.*?)\]/) ? n.match(/\[(.*?)\]/)[1] : '';
    }
    
    let fields = {};
    $('#newtruckexpfields').find(":input").map(function(el){ if(this.name) { if(this.name == 'state_operated_in'){ fields[(this.name)] = $('#newStateOperatedIn').val().join(','); }else { fields[(this.name)] = this.value; } } }).get();
    

    let invalidfields = 0;
    
    for( let x in fields )
    {
        invalidfields += fields[x] === '' ? 1 : 0;
    }
    
    if( invalidfields > 0 ){
        
        errornew.value ="You need to enter information in above.";
        return;
    }else {
        errornew.value =false;
    }
    
    
    previous_truck_experience.value = [...previous_truck_experience.value, fields];
    
    
    // reset
   
    var $select = $('#newStateOperatedIn').selectize();
     var control = $select[0].selectize;
     control.clear();
   
   $('#newtruckexpfields').find(":input").val("");
   
   
   
   //showAddNewForm.value = false;

}

function updateTruckExpRow(index,change)
{
    
    previous_truck_experience.value = previous_truck_experience.value.map((addrss, i) => (i === index ? { ...addrss, ...change } : addrss));
}

function removePrevTruckExp(row) {
  previous_truck_experience.value = previous_truck_experience.value.filter(t => t !== row);
  shouldAddPreviousTruckExp();
}


function RegulationSection(props)
{
    return html`
        <div class="mb-2 text-left font-bold"><${SubHeading} text="TO BE READ AND e-SIGNED BY APPLICANT/DRIVER" /></div>
        <div style="border-bottom: 2px solid #c5c6ca;">
        <${Scrollbars} style=${{  height: 300 }}>
    
        <div>
        <p>This certifies that I completed this application and all entries and information is true and correct to the best of my knowledge.</p>
<p>I authorize this company to make such investigations and inquiries of my personal, employment, financial or medical history and other related matters as may be necessary in arriving at an employment decision. (Generally, inquiries regarding medical history will be made only if and after a conditional offer of employment has been extended.) I hereby release employers, schools, health care providers and other persons from all liability in responding to inquiries and releasing information in connection with my application.</p>
<p>In the event of employment, I understand that false of misleading information given in my application or interviews may result in discharge. I understand, also, that I am required to abide by all rules and regulations of the Company.</p>
<p>I understand that information I provide regarding current and/or previous employers may be used, and those employer(s) will be contacted, for the purpose of investigation my safety performance history as required by 49 CFR 391.23(d) and (e). I understand that I have the right to:</p>
<p>► Review information provided by previous employers</p>
<p>► Have errors in the information corrected by previous employers and for those previous employers to re-send the</p>
<p>corrected information to the prospective employer; and</p>
<p>► Have a rebuttal statement attached to the alleged erroneous information, if the previous employer(s) and I cannot agree on the accuracy of the information.</p>
<p>I understand that I am required to abide by all rules of this company, laws of the states, and regulations of the FMCSA.</p>
</div>
</${Scrollbars}>
</div>
    `;
}
    
    
function PreviousTrafficConvRow({ row, index })
{
    const getStates = () => (this.context.store.getState().states); 
    const multidropdownd = useRef(null);
    
    

    const onChange = (e) => {
        const extractname = e.target.name.slice(1,-1).split('][')[1];
        updateTruckExpRow(index, { [extractname]: e.target.value});
    }
    
    const selectizeconf  = {
        
       valueField: 'value',
        labelField: 'label',
        searchField: 'label',
        onChange: function(v){
             /*onChange({ 'target' : {'name' : $(this.$input).attr('name'), 'value' : v.join(',')} });*/
        },
       options: getStates(),
        plugins: ["remove_button"],
      //  delimiter: ",",
      items: row.state_operated_in ? row.state_operated_in.split(',') : []
  
    };
    
    useEffect(() => {
        console.log( "getStates : ", getStates() );
       /* setTimeout(() => {
            
           
            
        }, 100);*/
        const s =  $(multidropdownd.current).selectize(selectizeconf);
        /*if(s)
        {
            const control = s[0].selectize;
            control.destroy();
            $(multidropdownd.current).selectize(selectizeconf);
        }*/

    }, [row.state_operated_in]);
    
    
    
    return html `<div>
                <${FieldContainer} columns="4">
                    <${FormControlSelect}  onChange="${onChange}" wrapselect="true" label="Type"  defaultval="${row.truck_type}" name="truck_experiences[${index}][truck_type]"  options="${ [{label: '', value: ''},{label: 'Tractor w/ Flatbed', value: 'Tractor w/ Flatbed'},{label: 'Tractor w/ Van', value: 'Tractor w/ Van'},{label: 'Tractor w/ Reefer', value: 'Tractor w/ Reefer'}, {label: 'Tractor w/ Tank', value: 'Tractor w/ Tank'}, {label: 'Straight Truck', value: 'Straight Truck'},{label: 'Dump Truck', value: 'Dump Truck'},{label: 'Other specify', value: 'Other specify'}] }" />
                
                        <${InputField} value="${row.trailer_length}" name="truck_experiences[${index}][trailer_length]" onChange="${onChange}" label="Trailer Length" placeholder=""  />
                        
                        <${InputField}  value="${row.years_of_experience}" name="truck_experiences[${index}][years_of_experience]" type="number" label="Years of experience" placeholder="" onChange="${onChange}" />
                        
                        <${InputField}  value="${row.approx_miles}" name="truck_experiences[${index}][approx_miles]" type="number" label="Approximate number of miles" placeholder=""  onChange="${onChange}" />
                        
                    </${FieldContainer}>
                    
                    <${FieldContainer} columns="1">
                        
                        
                       
                        
                        
                    <div>    
                        <label  class="block text-gray-900 text-sm leading-6 mb-[2px]">States Operated In</label>
                  
                        <select  key="${index}-${row.state_operated_in}" placeholder="Select States" ref="${multidropdownd}"  class="multiple-select full-width" name="truck_experiences[${index}][state_operated_in][]" multiple="multiple" data-width="200">
                            
                        </select>
                    
                    </div>
                       
                        
                    </${FieldContainer}> 
                    
                   
                </div>`;
}


const onChangeNat = (e) => {
    /*const total = $(e.target).closest('.nature_extent_sub').find('input[value="1"]:checked').length;
    nature_extent_qa_checked.value = total;*/
    
}

function extraFieldsAISection({ name_date, name_details })
{
	return html `<div class="ml-5"><${FieldContainer} columns="2">
				    <${InputField} datepicker="true" label="Date" placeholder="Select Date" name="${name_date}" required="true" />
				    <${InputTextarea} label="Details" placeholder="Enter Details Here..." name="${name_details}" required="true" />
				</${FieldContainer}></div>`;
}

function extraFieldsJSection({ name })
{
	return html `<div class="ml-4 sm:ml-0 mt-2 sm:mt-0"><${FieldContainer} columns="3">
				    <${InputInlineOptions} indent="indent-0 sm:indent-8" required type="radio" label="Did you complete the  return-to-duty process?" name="${name}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />
				    
				</${FieldContainer}></div>`;
}

function NatureAndExtentSubSection()
{
    
const updateCheckbox = (change, index) => {
        nature_extent_qa1.value = nature_extent_qa1.value.map((addrss, i) => (i === index ? { ...addrss, ...change } : addrss));
        
    }
    
    
    
    console.log("re rendering NatureAndExtentSubSection...");
    
return html `<div class='nature_extent_sub'><${FieldContainer} columns="1">
	            ${ nature_extent_qa1.value.map( (g, gi) => html `<${NatureAndExtentSubSectionRow} g="${g}" updateCheckbox="${updateCheckbox}" gi="${gi}" />` ) }
	        </${FieldContainer}></div>`;
}

function NatureAndExtentSubSectionRow({g, gi, updateCheckbox})
{
    
    const checked = useSignal(gi.checked);
    
    const onChangeNat = (e, index) => {
        
        checked.value  = e.target.value;
        

    }
    
    return html `<${Fragment}><input type="hidden" name="qa[${gi}][label]" value="${g.label}"  /><input type="hidden" name="qa[${gi}][name]" value="${g.name}"  /><${InputOptions} type="radio" label="${g.label}" name="qa[${gi}][value]"  value="${checked}" required="true" onChange="${(e) => onChangeNat(e, gi)}" options="${g.options }"  /> ${ checked == '1' && html `<${extraFieldsAISection} name_date="qa[${gi}][on_date]" name_details="qa[${gi}][details]" />` }</${Fragment}>`;
}

function NatureAndExtentSubJSectionRow({g, gi})
{
    
    const checked = useSignal(gi.checked);
    
    const onChangeNat = (e, index) => {
        
        checked.value  = e.target.value;
        

    } 
    
    return html `<${Fragment}><div><input type="hidden" name="qa[${8+gi}][label]" value="${g.label}"  /><input type="hidden" name="qa[${8+gi}][name]" value="${g.name}"  /><${InputOptions} required="true" type="radio" label="${g.label}" name="qa[${8+gi}][value]" value="${checked}" onChange="${(e) => onChangeNat(e, gi)}" options="${g.options }"  />${ checked == '1' && html `<${extraFieldsJSection} name="qa[${8+gi}][has_comleted_returnduty]" />` }</div></${Fragment}>`;
}


function NatureAndExtentSubJSection()
{ 
    
   

	return html`
		<div class="">
			<strong>J. In the two years prior to the date of this application, (for DOT-controlled substance and alcohol related testing): </strong>
            <div class="nature_extent_j_sub">
			<${FieldContainer} columns="1">
	            ${ nature_extent_qa2.value.map( (g, gi) => html `<${NatureAndExtentSubJSectionRow} g="${g}" gi="${gi}" />` ) }
	        </${FieldContainer}>
	        </div>
		</div>
	`;
}

function newRowError({ msg })
{
	return  msg.value ? html `<${AlertError} msg="${msg.value}" /><br />` : '';
}

function TruckDetailsSection({ data })
{
    const getStates = () => (this.context.store.getState().states); 
    const multidropdown = useRef(null);
    
    
    


    useEffect( ()=>{
        
        if( data )
        {
            //setShow();
            if( data && data.value && data.value.natureexperiencesvehicles && data.value.natureexperiencesvehicles.length > 0 )
            {
                  previous_truck_experience.value = data.value.natureexperiencesvehicles;
                  //setShow(true);
                 // console.log("AddressSection", previous_truck_experience.value);
            }
            
            if( data && data.value && data.value.natureexperiences && data.value.natureexperiences.length > 0 )
            {
                  //previous_truck_experience.value = data.value.natureexperiences;
                  //setShow(true);
                 // console.log("AddressSection", previous_truck_experience.value);
            }
        }
        
    },  [data]);
    
    
    
    return html `
    <div class="mt-8">
        <${SubHeading} text="ADD" />

        
        <${addNewTruckInfoFields} />

        <${newRowError} msg="${errornew}" />

        
        <div>
            ${ allowAddPreviousTruckExp.value && shouldAddPreviousTruckExp() && html `<${addMoreTruckInfoButton} />` }
            
            ${ previous_truck_experience.value.length > 0 && html`<${PreviousRecordSection} rows="${previous_truck_experience.value}" />` }
        </div>
        
        </div>
    `;
}

function addNewTruckInfoFields()
{
    const getStates = () => (this.context.store.getState().states); 
    const multidropdown = useRef(null);
    useEffect( () =>{
        
        const s =  $(multidropdown.current).selectize({
           valueField: 'value',
            labelField: 'label',
            searchField: 'label',
            
           options: getStates(),
            plugins: ["remove_button"],
            delimiter: ",",
      });
        
    }, [] );
    return html `<div id="newtruckexpfields">
        <${FieldContainer} columns="4">
                <${FormControlSelect} wrapselect="true" label="Type" name="truck_type"  options="${ [{label: '', value: ''},{label: 'Tractor w/ Flatbed', value: 'Tractor w/ Flatbed'},{label: 'Tractor w/ Van', value: 'Tractor w/ Van'},{label: 'Tractor w/ Reefer', value: 'Tractor w/ Reefer'}, {label: 'Tractor w/ Tank', value: 'Tractor w/ Tank'}, {label: 'Straight Truck', value: 'Straight Truck'},{label: 'Dump Truck', value: 'Dump Truck'},{label: 'Other specify', value: 'Other specify'}] }" />
                
                <${InputField} label="Trailer Length" placeholder="" name="trailer_length" onChange="${()=> console.log('test')}" />
                
                <${InputField} type="number" label="Years of experience" placeholder="" name="years_of_experience" onChange="${()=> console.log('test')}" />
                
                <${InputField} type="number" label="Approximate number of miles" placeholder="" name="approx_miles" onChange="${()=> console.log('test')}" />
                
            </${FieldContainer}>
            
            <${FieldContainer} columns="1">
                
                
                

                <div>    
              <label for="P0-1723212323" class="block text-gray-900 text-sm leading-6 mb-[2px]">States Operated In</label>
              
              <select id="newStateOperatedIn" placeholder="Select States" ref="${multidropdown}" class="multiple-select full-width" name="state_operated_in" multiple="multiple" data-width="200">
              
                </select>
                
                </div>
                
                
            </${FieldContainer}>
        </div>`;
}

function addMoreTruckInfoButton()
{
    return html`<div class="text-right">
                <a href="javascript:void(0);" class="px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 " onClick="${addMoreTruckExp}">+ Add</a>
                </div>`;
}

function PreviousRecordSection({rows= [] })
{
	return html`<${Fragment}><${SubHeading} text="PREVIOUS RECORD" />

            <div class="bg-[#f3f3f3]  rounded-md border-black border-5 shadow-1">
                <${AccordionGroup}>
                ${rows.map( (row ,index, arr) => ( html ` 
                <${Accordion} onRemove="${ (e) => removePrevTruckExp(row) }" first="${index === 0}" last="${index === arr.length - 1}" heading="${ row.truck_type + ' ' + row.trailer_length+ ' ' +row.years_of_experience+' Years Experience'  }">
                        <${PreviousTrafficConvRow} row="${row}" index="${index}" />
                    </${Accordion}>`) ) }
                    
                </${AccordionGroup}>
                
                </div></${Fragment}>`;
}

function FieldsContainer({data})
{
    return html `<${Fragment}>
	
            <${TruckDetailsSection} data="${data}" />
            
            <div class="mt-8">
            <${SubHeading} text="OTHER INFORMATION" />
            </div>
			
			<${NatureAndExtentSubSection} />
	       
	        
	        <${NatureAndExtentSubJSection} />
	        
	        ${ any_nature_extent_j_checked.value > 0 && html `
	            <${FieldContainer} columns="1">
	                <${InputOptions} type="radio" label="5. Did you complete the  return-to-duty process?" name="has_comleted_returnduty" onChange="${() => console.log(1234)}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />
	            </${FieldContainer}>
	        ` }
	        
	        
        </${Fragment}>`;
}


function Form({data}){
    return html `
    <div>
         
        <${FieldsContainer} data="${data}" />
        <${RegulationSection} />
       <div class="relative flex gap-x-3 mt-5 mb-8"><div class="flex align-top h-6 items-center">
        	<input id="agree_checkbox" required name="agree_checkbox" type="checkbox" class="h-6 w-6 rounded border-gray-300 text-blue-600 focus:ring-blue-600" />
        	<div class="ml-2 "><label for="agree_checkbox" required="true" class="font-medium text-gray-900"></label>I understand that i am required to abide by all rules of this company, laws of states and regulations of the FMCSA</div></div>
        </div>
        
        <div class="flex flex-col sm:flex-row gap-4">
	
        	<div class="flex-1 text-center">
        	
        		<div class="bg-[#ffedd3]  border-b border-gray-400 p-3 mb-2 relative overflow-hidden cursor-pointer" onClick="${openSignModal}" style="padding:19px;">
        			<div class="absolute left-0 top-0 h-1 w-1">
        
        	  			<div style="display:none;" class="absolute left-[-10px] top-[-1px] h-3 w-[25px] transform -rotate-45 bg-red-600 text-center text-white font-semibold py-1 -z-1"></div>
        
        			</div>
        			<img id="canvasImage3" src="" />
        			<input name="signature" id="signature-input" type="hidden" value="" />
        			<i class="fa-solid fa-feather text-gray-500"></i> <span class="text-gray-500">Signature</span>
        
        		</div>
        		<p>Applicant's Signature</p>
        	</div>
        
        	<div class="flex-1 text-center">
        	
        		<div class="bg-[#ffedd3]  border-b border-gray-400 p-3 mb-2 relative overflow-hidden">
        			<div class="absolute left-0 top-0 h-1 w-1">
        
        	  			<div style="display:none;" class="absolute left-[-10px] top-[-1px] h-3 w-[25px] transform -rotate-45 bg-red-600 text-center text-white font-semibold py-1 -z-1"></div>
        
        			</div> 
        			
        			 <${InputField} type="text" value="${''}" datepicker="true" label="" placeholder="Enter signed date" required name="signed_on" onChange="${()=> console.log('test')}" />
        			 
        			
        
        		</div>
        		<p>Date Signed</p>
        	</div>
        
        </div> 
        <br />
        
        ${ errormessage.value && html `<${AlertError} msg="${errormessage.value}" />` }
    </div>
    
    `;
}

function FormContainer({ currentStep, data, _validate })
{
    const f_ref = useRef(null);
    const onSubmit = (e) => {
        e.preventDefault();
        
        const handleValidation = () => {
        
        let formIsValid = previous_truck_experience.value.length > 0;
        
        return formIsValid;
    }
    
        if( !handleValidation() )
        {
            errormessage.value= ('Click "Add" button before clicking "Save & Continue" button');
            return false;
        }
        
        if( $('#signature-input').val() == '' )
        {
            errormessage.value='You need to sign the document first.';
            return false;
        }
        
        errormessage.value=false;
        
        submitLoader.value = true;
        const formdata = new FormData(e.target);
        const url = '/applydrivernature/save'; 
        fetch(url, {
            method: 'post',
            body: formdata
        }).then(r => r.json()).then(r => {
            if( r.success )
            {
                //alert('sucess');
                _validate(6);
                route(r.redirect);
                
               // location = r.redirect;
            }else {
                alert('error');
            }
            submitLoader.value = false;
        }).catch(e => {
            alert('error');
            submitLoader.value = false;
        });
    };
    
    const _prev = () => {
        const backstepto = '5';
        const path = getCurrentUrl().split('/'); 
        path[path.length-1] = backstepto;
        route(path.join('/'));
    }
    
    useEffect( () => {
        /*$.validator.setDefaults({
            errorClass: "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400",
            wrapper: "p",
    		submitHandler: function(form, event) {
    			onSubmit(event);
    		}
    	});*/
        //$( f_ref.current ).validate();
        
    }, [] );
    
    const container = document.getElementById('modals');

    
    return html `
    <div>
         <form method="post" onSubmit="${onSubmit}" ref="${f_ref}" class="text-left" >
            <input type="hidden" name="_token" value="${csrf_token}" />
            <input type="hidden" name="driver_id" value="${data.value && data.value.driver_id}" />
            <${Form} data="${data}" />
            <div class="flex items-center justify-between mt-8">
                 <button type="button" onClick="${(e) => _prev()}" class="flex items-center text-gray-700 text-sm font-medium rounded hover:underline focus:outline-none">
                    <svg class="h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
                    <span class="mx-2">Back</span>
                </button>
                <button type="submit" class="flex items-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 ${submitLoader.value ? 'disabled' : ''}">${submitLoader.value ? html `<${Fragment}><svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg> Processing </${Fragment}>` : html`<${Fragment}><span>Save & Continue</span>
<svg class="h-5 w-5 mx-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg></${Fragment}>`}</button>
            </div>
         </form>
         
         ${createPortal( html `<${SignModalComponent} />`,  container)}

    </div>`;
}


export default function DriverNatureAndExperiance({ data, currentStep, afterValid })
{
    const _validate =(s) => {
        
       // step1progress.value = 20;
        
    	afterValid(s);
  	}
  	
    return html `<${FormContainer} currentStep="${currentStep}" data="${data}" _validate="${_validate}" />`;
} 