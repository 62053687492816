import { html } from 'htm/preact';
import Router from 'preact-router';
import wizard from '../views/driverwizard/wizard.js';
import {leftSidebar} from '../layout/wizardsidebar.js';
import { connect } from "redux-zero/preact";
import { useEffect } from 'preact/hooks';


/*const actions = store => ({
  setStates: state => ({ states: state.count + 1 }),
  decrement: state => ({ count: state.count - 1 })
});
*/
function applyContainer({ setUSAStates, states}) {
	
    useEffect( () => {
        
        /*fetch('/address/states').then( r => r.json() ).then(rows => {
            
           console.log(rows); 
            setUSAStates(rows);
        });*/
        
        setUSAStates(window.countrystates); 
        
    }, [] );

	return html`
	<div>
		<${wizard} states="${states}" />
	</div>`;
}

const actions = store => ({
    setUSAStates: (state, v) => {
         
        return {...state, states: v};
     }
  
});





const mapToProps = (state) => (state);

export default connect(
  mapToProps,
  actions
)(applyContainer);