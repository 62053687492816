import  createStore  from 'redux-zero';

const initialState = {
	loggedin_id: null,
	loggedin_type: null,
	states: []

}

const store = createStore(initialState);

export default store;