import { Fragment, toChildArray } from 'preact';
import { html } from 'htm/preact';
import { useState } from 'preact/hooks';


function Alert({ bg, msg, icon = '' })
{
    const iconhtml = html `<svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"></path></svg>`;
    
    return html `<div class="flex items-center ${bg} text-white text-sm font-bold px-4 py-3" role="alert">
    ${iconhtml}
  <p>${msg}</p>
</div>`;
}

function AlertSuccess({ msg })
{
    return html `<${Alert} bg=${'bg-green-500'} msg="${msg}" />`;
}

function AlertError({ msg })
{
    return html `<${Alert} bg=${'bg-red-500'} msg="${msg}" />`;
}

function AlertInfo({ msg })
{
    return html `<div class="flex items-center bg-yellow-200 text-black text-sm font-medium px-4 py-4 rounded-lg" role="alert"><i class="fa fa-info-circle mr-1"></i><p>${msg}</p></div>`;
}

export { AlertError, AlertSuccess, AlertInfo };
