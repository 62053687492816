import { html } from 'htm/preact';
import {Fragment} from 'preact';
import { route } from 'preact-router';

function WizardSteps({currentStep, steps=[], uid})
{
    
    const w = parseInt(currentStep * 25);
    
    const isStep1 = currentStep == 1 ? 1 : false;
    const isStep2 = currentStep == 2 ? 2 : false;
    const isStep3 = currentStep == 3 ? 3 : false;
    const isStep4 = currentStep == 4 ? 4 : false;
    
    const onClick = (e, s) => {
        
        route('/apply/' + uid + '/' + s);
    }
    
	return html`
		<div class="w-full py-6"> 
		  <div class="flex flex-wrap sm:flex-nowrap">
		    
		    <div class="w-1/4 mb-3 sm:mb-0">
		      <div class="relative mb-2">
		        <div class="w-10 h-10 mx-auto ${currentStep >= 1 ? 'bg-blue-600' : 'bg-white border-2 border-gray-200'} rounded-full text-lg  flex items-center">
		          <span class="text-center ${currentStep >= 1 ? 'text-white' : 'text-gray-600'} w-full">
		          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-full" height="24" width="24">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                    </svg>
		            
		          </span>
		        </div>
		      </div>

		      <div class="text-xs text-center md:text-base ${currentStep == 1 ? 'font-bold' : ''}"> ${ currentStep >= 1 ? html `<a href="${('/apply/' + uid + '/' + 1)}">Step 1</a>` : 'Step 1' } </div>
		    </div>
		  
		    <div class="w-1/4 mb-3 sm:mb-0">
		      <div class="relative mb-2">
		      <div class="absolute flex align-center items-center align-middle content-center" style="width: calc((100% - 2.5rem) - 1rem); top: 50%; transform: translate(-50%, -50%);">
		      <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1 hidden md:block"><div class="w-0 bg-blue-300 py-1 rounded"></div></div></div>
		      
		        <div class="w-10 h-10 mx-auto ${currentStep >= 2 ? 'bg-blue-600' : 'bg-white border-2 border-gray-200'} rounded-full text-lg  flex items-center">
		          <span class="text-center ${currentStep >= 2 ? 'text-white' : 'text-gray-600'} w-full">
		            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-full" width="24" height="24">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                    </svg>
		          </span>
		        </div>
		      </div>

		      <div class="text-xs text-center md:text-base ${currentStep == 2 ? 'font-bold' : ''}">${ currentStep >= 2 ? html `<a href="${('/apply/' + uid + '/' + 2)}">Step 2</a>` : 'Step 2' } </div>
		    </div>

		    <div class="w-1/4 mb-3 sm:mb-0">
		      <div class="relative mb-2">
		        <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
		          <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1  hidden md:block">
		            <div class="w-0 bg-blue-300 py-1 rounded"></div>
		          </div>
		        </div>

		        <div class="w-10 h-10 mx-auto ${currentStep >= 3 ? 'bg-blue-600' : 'bg-white border-2 border-gray-200'} rounded-full text-lg ${currentStep >= 3 ? 'text-white' : 'text-black'} flex items-center">
		          <span class="text-center ${currentStep >= 3 ? 'text-white' : 'text-gray-600'} w-full">
		            <svg class="w-full" width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    
    
    
                        <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="ic_fluent_contact_card_24_regular" fill="currentColor" fill-rule="nonzero">
                                <path d="M19.75,4 C20.9926407,4 22,5.00735931 22,6.25 L22,17.754591 C22,18.9972317 20.9926407,20.004591 19.75,20.004591 L4.25,20.004591 C3.00735931,20.004591 2,18.9972317 2,17.754591 L2,6.25 C2,5.00735931 3.00735931,4 4.25,4 L19.75,4 Z M19.75,5.5 L4.25,5.5 C3.83578644,5.5 3.5,5.83578644 3.5,6.25 L3.5,17.754591 C3.5,18.1688046 3.83578644,18.504591 4.25,18.504591 L19.75,18.504591 C20.1642136,18.504591 20.5,18.1688046 20.5,17.754591 L20.5,6.25 C20.5,5.83578644 20.1642136,5.5 19.75,5.5 Z M9.75,12.5 C10.1642136,12.5 10.5,12.8357864 10.5,13.25 L10.5,13.7427321 L10.4921036,13.8512782 C10.3293159,14.964219 9.39767421,15.5009403 7.99995063,15.5009403 C6.60213369,15.5009403 5.67047899,14.9636623 5.50787101,13.8501298 L5.5,13.7417575 L5.5,13.25 C5.5,12.8357864 5.83578644,12.5 6.25,12.5 L9.75,12.5 Z M13.2522936,12.9961404 L17.75,12.9961404 C18.1642136,12.9961404 18.5,13.3319269 18.5,13.7461404 C18.5,14.1258362 18.2178461,14.4396314 17.8517706,14.4892938 L17.75,14.4961404 L13.2522936,14.4961404 C12.83808,14.4961404 12.5022936,14.160354 12.5022936,13.7461404 C12.5022936,13.3664447 12.7844475,13.0526495 13.150523,13.002987 L13.2522936,12.9961404 L17.75,12.9961404 L13.2522936,12.9961404 Z M8,8.50218109 C8.82841293,8.50218109 9.4999743,9.17374246 9.4999743,10.0021554 C9.4999743,10.8305683 8.82841293,11.5021297 8,11.5021297 C7.17158707,11.5021297 6.5000257,10.8305683 6.5000257,10.0021554 C6.5000257,9.17374246 7.17158707,8.50218109 8,8.50218109 Z M13.2522936,9.5 L17.75,9.5 C18.1642136,9.5 18.5,9.83578644 18.5,10.25 C18.5,10.6296958 18.2178461,10.943491 17.8517706,10.9931534 L17.75,11 L13.2522936,11 C12.83808,11 12.5022936,10.6642136 12.5022936,10.25 C12.5022936,9.87030423 12.7844475,9.55650904 13.150523,9.50684662 L13.2522936,9.5 L17.75,9.5 L13.2522936,9.5 Z" id="🎨-Color">
                    
                    </path>
                            </g>
                        </g>
                    </svg>
		          </span>
		        </div>
		      </div>

		      <div class="text-xs text-center md:text-base ${currentStep == 3 ? 'font-bold' : ''}">${ currentStep >= 3 ? html `<a href="${('/apply/' + uid + '/' + 3)}">Step 3</a>` : 'Step 3' } </div>
		    </div>
		    
		    

		    <div class="w-1/4 mb-3 sm:mb-0">
		      <div class="relative mb-2">
		        <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
		          <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1  hidden md:block">
		            <div class="w-0 bg-blue-300 py-1 rounded" ></div>
		          </div>
		        </div>

		        <div class="w-10 h-10 mx-auto ${currentStep >= 4 ? 'bg-blue-600' : 'bg-white border-2 border-gray-200'}  rounded-full text-lg text-white flex items-center">
		          <span class="text-center ${currentStep >= 4 ? 'text-white' : 'text-gray-600'} w-full">
		            
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-full" width="24" height="24">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                    </svg>
		          </span>
		        </div>
		      </div>

		      <div class="text-xs text-center md:text-base ${currentStep == 4 ? 'font-bold' : ''}">${ currentStep >= 4 ? html `<a href="${('/apply/' + uid + '/' + 4)}">Step 4</a>` : 'Step 4' } </div>
		    </div>
		    
		    <div class="w-1/4 mb-3 sm:mb-0">
              <div class="relative mb-2">
                <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                  <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1  hidden md:block">
                    <div class="w-0 bg-blue-300 py-1 rounded"></div>
                  </div>
                </div>

                <div class="w-10 h-10 mx-auto ${currentStep >= 5 ? 'bg-blue-600' : 'bg-white border-2 border-gray-200'} rounded-full text-lg ${currentStep >= 5 ? 'text-white' : 'text-black'} flex items-center">
                  <span class="text-center ${currentStep >= 5 ? 'text-white' : 'text-gray-600'} w-full">
                    <svg  style="    display: inline-block;" version="1.0" fill="currentColor" stroke="none" xmlns="http://www.w3.org/2000/svg"
                         width="24px" height="24px" viewBox="0 0 100.000000 100.000000"
                         preserveAspectRatio="xMidYMid meet">
                        
                        <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)">
                        <path d="M245 929 c-4 -6 14 -44 39 -85 25 -41 46 -78 46 -81 0 -4 -12 -24
                        -26 -44 -14 -21 -23 -43 -19 -48 3 -6 28 -11 54 -11 l47 0 21 -50 c12 -28 27
                        -50 33 -50 6 0 21 23 33 50 15 35 26 49 37 46 8 -3 38 -7 66 -11 73 -9 77 6
                        18 66 l-48 49 39 40 c50 51 40 67 -32 52 -47 -10 -49 -10 -81 21 -32 31 -34
                        31 -48 13 -8 -11 -14 -23 -14 -28 0 -4 -33 12 -72 37 -78 49 -83 50 -93 34z
                        m194 -110 l17 22 19 -20 c10 -12 27 -21 38 -21 20 0 20 -1 2 -20 -18 -20 -18
                        -21 16 -56 l34 -36 -47 7 c-44 7 -48 6 -59 -19 -15 -32 -23 -33 -37 -1 -9 20
                        -19 25 -47 25 l-36 0 22 29 22 30 -28 46 -27 47 47 -28 47 -27 17 22z"/>
                        <path d="M835 750 c-55 -12 -107 -35 -126 -57 -9 -10 -38 -73 -64 -141 -39
                        -101 -51 -122 -64 -116 -9 4 -25 7 -36 7 -13 0 -28 11 -38 28 -19 34 -62 39
                        -101 14 -23 -16 -25 -14 -71 48 -26 36 -58 70 -72 76 -13 6 -69 11 -123 11
                        -93 0 -100 -1 -100 -20 0 -19 7 -20 99 -20 113 0 111 1 185 -97 26 -35 50 -63
                        53 -63 2 0 18 9 34 21 39 27 63 22 67 -16 5 -42 17 -51 45 -33 16 11 28 13 40
                        6 14 -7 17 -25 17 -103 0 -89 -1 -95 -20 -95 -12 0 -24 9 -29 22 -36 93 -180
                        101 -216 13 l-15 -35 -130 0 c-123 0 -130 -1 -130 -20 0 -19 7 -20 130 -20
                        l130 0 15 -35 c36 -88 180 -80 216 13 5 13 17 22 29 22 16 0 20 -7 20 -30 0
                        -49 30 -70 100 -70 70 0 100 21 100 70 l0 30 90 0 c83 0 90 2 90 20 0 19 -8
                        20 -167 22 l-168 3 -3 80 c-2 46 2 96 9 118 11 33 16 37 33 31 12 -4 83 -9
                        159 -11 133 -4 137 -3 137 16 0 20 -6 21 -128 21 -91 0 -133 4 -146 13 -18 13
                        -18 16 -1 68 44 133 77 161 208 175 56 6 67 10 67 25 0 16 -7 19 -42 18 -24
                        -1 -61 -5 -83 -9z m-360 -515 c50 -49 15 -135 -55 -135 -41 0 -80 39 -80 80 0
                        41 39 80 80 80 19 0 40 -9 55 -25z m263 -102 c-3 -27 -5 -28 -58 -28 -53 0
                        -55 1 -58 28 -3 27 -3 27 58 27 61 0 61 0 58 -27z"/>
                        <path d="M660 380 c-37 -37 -22 -76 44 -115 23 -14 57 -25 75 -25 42 0 81 38
                        81 80 0 19 -9 40 -25 55 -21 22 -33 25 -90 25 -52 0 -69 -4 -85 -20z m150 -35
                        c27 -32 -7 -73 -53 -61 -35 8 -82 50 -73 64 11 18 111 15 126 -3z"/>
                        </g>
                        </svg>
                  </span>
                </div>
              </div>

              <div class="text-xs text-center md:text-base ${currentStep == 5 ? 'font-bold' : ''}">${ currentStep >= 5 ? html `<a href="${('/apply/' + uid + '/' + 5)}">Step 5</a>` : 'Step 5' } </div>
            </div>

		    <div class="w-1/4 mb-3 sm:mb-0">
		      <div class="relative mb-2">
		        <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
		          <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1  hidden md:block">
		            <div class="w-0 bg-blue-300 py-1 rounded" style="width: 0%;"></div>
		          </div>
		        </div>

		        <div class="w-10 h-10 mx-auto ${currentStep >= 6 ? 'bg-blue-600' : 'bg-white border-2 border-gray-200'} rounded-full text-lg text-white flex items-center">
		          <span class="text-center ${currentStep >= 6 ? 'text-white' : 'text-gray-600'} w-full">
		          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-full" width="24" height="24">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
                    </svg>
		            
		          </span>
		        </div>
		      </div>

		      <div class="text-xs text-center md:text-base ${currentStep == 6 ? 'font-bold' : ''}">${ currentStep >= 6 ? html `<a href="${('/apply/' + uid + '/' + 6)}">Step 6</a>` : 'Step 6' } </div>
		    </div>
		  </div>
		</div>
	`;
}

function WizardHeading({ text })
{
	return html`<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">${text}</h2>`;
}

function WizardProgressBar({ heading,progress })
{
	return html `
	<${Fragment}>
		  <div class="flex justify-between mb-1">
			  <span class="text-base font-medium text-blue-700">${heading}</span>
			  
			</div>
			<div class="w-full bg-gray-200 rounded-full h-2.5">
			  <div class="bg-blue-600 h-2.5 rounded-full" style="width: ${progress}%"></div>
			</div>
			<div class="flex justify-between text-gray-500 mt-1"> <p>0%</p>  <p>100%</p></div>
			</${Fragment}>
	`;
}

function successText(props){

return html `<div class="max-w-xs bg-white border border-gray-200 rounded-xl shadow-lg" role="alert">
  <div class="flex p-4">
    <div class="flex-shrink-0">
      <svg class="flex-shrink-0 h-4 w-4 text-teal-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
      </svg>
    </div>
    <div class="ms-3">
      <p class="text-sm text-gray-700">
        ${props.message}
      </p>
    </div>
  </div>
</div>`;

}

function erorrText(props){

return html ` <div class="max-w-xs bg-white border border-gray-200 rounded-xl shadow-lg role="alert">
  <div class="flex p-4">
    <div class="flex-shrink-0">
      <svg class="flex-shrink-0 h-4 w-4 text-red-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
      </svg>
    </div>
    <div class="ms-3">
      <p class="text-sm text-gray-700">
        ${props.message}
      </p>
    </div>
  </div>
</div>`;

}

export {WizardSteps, WizardHeading, WizardProgressBar, successText, erorrText};