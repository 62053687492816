import { html } from 'htm/preact';
export default function topnav(props) {
	


	return html`
	    <nav
    class="flex flex-col sm:flex-row flex-wrap md:items-center px-4 md:px-5 lg:px-16 py-6 justify-between  fixed top-0 z-20 w-full bg-white">


    <div class="flex items-center justify-between sm:justify-around gap-x-2 font-serif">

      <a href="#!" class="py-1 flex items-center text-2xl">
        <img src="./img/inlet-logo.png" alt="logo" />
      </a>


      <!-- Hamburger -->
      <button class="sm:hidden cursor-pointer flex flex-col justify-center gap-y-1" id="hamburger" aria-label="menu">
        <div class="w-6 h-1 rounded-lg bg-white bar duration-300"></div>
        <div class="w-4 h-1 rounded-lg bg-white bar duration-300"></div>
        <div class="w-6 h-1 rounded-lg bg-white bar duration-300"></div>
      </button>

    </div>


    <ul class="hidden sm:flex justify-end items-center gap-x-7 gap-y-5 tracking-wide mt-5 sm:mt-0" id="links">
      <li class="table-caption mb-5 sm:mb-0">
        <a href="#!" class="active font-bold">Home</a>
      </li>
      <li class="table-caption mb-5 sm:mb-0">
        <a href="./applyscreening" class="">Apply</a>
      </li>
    

    </ul>

  </nav>
	
	`;
}