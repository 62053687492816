import { useRef, useEffect } from 'preact/hooks';
import { html } from 'htm/preact';

var _fullSignature = null;

function SignModalComponent(props)
{
    const signature_cont_ref  = useRef(null);
    const modal_ref  = useRef(null);
    
    useEffect( ()=> {
        
        _fullSignature = new fullSignature({

            container: "#signature_container_id",
            headerToolbar:"draw, type, upload_image, camera",
            description: '',
            guideline: { show :false },
            footerToolbar:{
            	draw:[
            		
            		{
            			row: "[clear]"
            		}
            	],
            	type:[
            		{
            			row: "[ font]"
            		},
            		{
            			row: "[clear]"
            		}
            	],
            	upload_image:[
            		{
            		row : "[resize]"
            		},
            		{
            		row : "[clear]"
            		}
            	],
            	camera:[
            		{
            		row : "[resize]"
            		},
            
            	]
            }
        });
    
       document.addEventListener('DOMContentLoaded', function() {
            //debugger;
           
      });
      
      _fullSignature.init(settings);
           $(".signature-actions .tab_actions").append('<div class="signature-row"><div class="signature-col"><button style="background:transparent; border:0;" type="submit" :class="{\'disabled\': emptycanvas }" form="signature_form" class="signature-btn download_buttons" ><i class="fa-solid fa-check"></i> Save</button></div></div>');
      
       /* $(modal_ref.current).modal({observeChanges:true, closable : false, onHide(){
         if( typeof _fullSignature_signal.value != 'undefined' )
        {
            _fullSignature_signal.value.stopCamera();
            _fullSignature_signal.value.clear();
            $(modal_ref.current).find('form :input').not(':hidden').val("");
        }
        }});*/
        
      
        
    }, [] )
    
    return html`
        <div id="default-modal" class="relative z-10 hidden" ref="${modal_ref}" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
  
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"> 
      
      <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600 bg-[#132b51] text-white">
    <h3 class="text-xl font-semibold text-white">
        Signature
    </h3>
    <button type="button" onClick="${()=> $('#default-modal').addClass('hidden')}" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
        </svg>
        <span class="sr-only">Close modal</span>
    </button>
</div>
        <div class="bg-white">
          <div class="p-4 md:p-5 ">
                
                <div ref="${signature_cont_ref}" id="signature_container_id" class="flex gap-4"></div> 
                <span x-text="emptycanvas"></span>
                
            </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
<div id="d" ref="${modal_ref}" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidde n fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600 bg-[#132b51] text-white">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Signature
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <!-- Modal body -->
            <div class="p-4 md:p-5 space-y-4">
                
                <div ref="${signature_cont_ref}" id="signature_container_id" class="flex"></div> 
                <span x-text="emptycanvas"></span>
                
            </div>
           
        </div>
    </div>
</div>
    `;
}

function openSignModal(e)
{
    e.preventDefault();
	console.log('openSignModal');
	$('#default-modal').removeClass('hidden');
	
	//fix for modal
	
	window.dispatchEvent(new Event('resize'));
}

function signformsubmit(t, e)
{
     e.preventDefault();
    	document.querySelector("#canvasImage").value=_fullSignature.toDataURL("image/jpeg");
    	
    	/*document.querySelector("#canvasImage2").value=_fullSignature.toDataURL("image/jpeg");*/
    	
    	var empty = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAAAAAAAAAAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDAAMCAgICAgMCAgIDAwMDBAYEBAQEBAgGBgUGCQgKCgkICQkKDA8MCgsOCwkJDRENDg8QEBEQCgwSExIQEw8QEBD/2wBDAQMDAwQDBAgEBAgQCwkLEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBD/wAARCACkAYIDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAn/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AKpgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//Z';
    	
    	if( _fullSignature.toDataURL("image/jpeg") == empty )
    	{
    	    
    	    alert('you need to fill your signature!');
    	    
    	    return false;
    	} 
    	
    	
       /*$("#signature_form").append('<input type="hidden" name="_token" value="">');*/
       var myCanvas = document.getElementById('canvasImage3');
       /* var ctx = myCanvas.getContext('2d');
        var img = new Image;
        img.onload = function(){
          ctx.drawImage(img,0,0); // Or at whatever offset you like
        };*/
        myCanvas.src =  _fullSignature.toDataURL("image/jpeg");
        document.querySelector("#signature-input").value=_fullSignature.toDataURL("image/jpeg");
        $('#default-modal').addClass('hidden');
        
   	    //document.querySelector("#signature_form").submit();
}

export {SignModalComponent, openSignModal, signformsubmit};