import { render, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { html } from 'htm/preact';
import { signal, useSignal } from "@preact/signals";
import moment from 'moment';
import IMask from 'imask';


import {InputOptions, FormControlSelect, InputField, InputTextarea, FieldContainer, FieldColumn, FieldLabel, PhoneField} from '../../components/FormControls.js';
import {SignModalComponent, openSignModal, signformsubmit} from '../../components/SignModal.js';
import {Accordion, AccordionGroup} from '../../components/Accordion.js';
import $ from 'jquery';

import selectize from 'selectize';

import {AlertSuccess, AlertError, AlertInfo} from '../../components/Alert.js';
import {SubHeading} from '../../components/Heading.js';


const errormessage = signal(false);

import { route, getCurrentUrl } from 'preact-router';

const submitLoader = signal(false);

const previous_employers = signal([]);
const stayed_years = signal([]);
const allowAddPreviousEmployer = signal(true);
const currentAddDateFrom = signal(null);
const currentAddDateTo = signal(null);

   

const total_stayed_years = (arr) =>
{
    return arr.reduce((a, b) => a + b, 0);
 
}


const getSumOfDateDiff = (date_arr) =>
{
    let total = 0;
    
    for (var i = 0; i < date_arr.length; i++) {
    	
    	if(date_arr[i].date_from && moment(date_arr[i].date_from).isValid() && date_arr[i].date_to && moment(date_arr[i].date_to).isValid())
    	{
    	    const a = moment(date_arr[i].date_from);
            const b = moment(date_arr[i].date_to);
            const diff = b.diff(a, 'years'); 
            
            total+= diff;
    	}
    }
    return total;
}

const shouldAddPreviousEmployer = () => {
    const from = moment(currentAddDateFrom.value);
    const to = moment(currentAddDateTo.value);
    let years = 0;
    if( from.isValid() && to.isValid() )
    {
        //debugger;
        const diff = to.diff(from, 'years');
        const stayed_yearsv = diff;
        years+=diff;
        
       // console.log((diff >= 3));
    }
    
    years += getSumOfDateDiff(previous_employers.value);
    
    allowAddPreviousEmployer.value = years < 10;
    return true;
}

function getNewEmployerFields()
{
    const employer_fields = {employer_name: $("#currentEmployerName").val(), supervisor_name: $("#currentEmployerSupervisorName").val(), has_safety_regulations: $("#EmployerHasSafetyReg").find('input:checked').val(), has_drug_testing: $("#EmployerHasDrugTesting").find('input:checked').val(), address: $("#EmployerAddress").val(), phone: $("#EmployerPhone").val() ,date_from: $("#employedFrom").val(), date_to: $("#employedUntil").val(), position: $('#EmployerPostion').val(), no_of_accidents : $('#EmployerNoOfAccidents').val(), states_drive_in : $('#EmployerStateDriveIn').val().join(','), reason_for_leaving: $('#EmployerReasonLeaving').val()};
    
    return employer_fields;
}

function getNewEmployerFieldsError()
{
    const employer_fields = getNewEmployerFields();

    let invalidfields = 0;
    
    for( let x in employer_fields )
    {
        invalidfields += employer_fields[x] === '' ? 1 : 0;
    }
    
    if( invalidfields > 0 ){
        
         return 'Please enter employer information.';
    }
    
    return false;
}

function addMoreEmployer(e , error)
{
     
    e.preventDefault();
    
    const employer_fields = getNewEmployerFields();
    const hasError = getNewEmployerFieldsError();
    
    errormessage.value = '';
   
    if( hasError )
    {
        error.value = hasError;
        return false;
    }else {
        error.value = '';
    }
    
    
    previous_employers.value = [...previous_employers.value, employer_fields];
    
    
    // reset
   // $("#crr-address1-field,#crr-address1-field,#crr-city-field,#crr-state-field,#crr-zip-field,#crr-addr-datefrom-field,#crr-addr-dateto-field").val(''), $('#crr-addr-datefrom-field,#crr-addr-dateto-field').next('input').val("");
    
    var $select = $('#EmployerStateDriveIn').selectize();
     var control = $select[0].selectize;
     control.clear();
     
     const $flatpickrFrom = document.querySelector("#employedFrom")._flatpickr;

    $flatpickrFrom.clear();
    
    const $flatpickrTo = document.querySelector("#employedUntil")._flatpickr;
    $flatpickrTo.clear();
}

function updatePrevEmployerFrom(index,change)
{
    
    previous_employers.value = previous_employers.value.map((addrss, i) => (i === index ? { ...addrss, ...change } : addrss));
}

function removePrevEmployer(row) {
    //debugger;
  previous_employers.value = previous_employers.value.filter(t => t !== row);
  shouldAddPreviousEmployer();
}


function PreviousEmployerRow(props)
{
    const row = props.row;
    const index = props.index;
    
    const multidropdown = useRef(null);
    
    const onChange = (e) => {
        const extractname = e.target.name.slice(1,-1).split('][')[1];
        updatePrevEmployerFrom(index, { [extractname]: e.target.value});
    }
    
    const getStates = (t) => (t.context.store.getState().states); 
    
    useEffect( () => {
        const _self = this;
       const s =  $(multidropdown.current).selectize({
             valueField: 'value',
              labelField: 'label',
              searchField: 'label',
              
             options: getStates(_self),
              plugins: ["remove_button"],
              delimiter: ",",
            items: row.states_drive_in ? row.states_drive_in.split(',') : []
        });
       
        
    }, [] ); 
        

    return html `
    
        <${FieldContainer} columns="2">
                <${InputField} value="${row.employer_name}" label="Employer Name"  placeholder="Enter Employer Name" name="previos_employer[${index}][employer_name]" onChange="${onChange}" />
                
                <${InputField} value="${row.supervisor_name}" label="Supervisor Name"  placeholder="Enter Supervisor Name" name="previos_employer[${index}][supervisor_name]" onInput="${onChange}" />
            </${FieldContainer}>

            

            

            <${FieldContainer} columns="1">

            	<${InputField} label="Address"  value="${row.address}"  placeholder="Enter address" name="previos_employer[${index}][address]"   onChange="${()=> console.log('test')}" />

            	

            </${FieldContainer}>

            <${FieldContainer} columns="2">
                <${PhoneField} value="${row.phone}"  label="Phone" placeholder="Enter Phone"  name="previos_employer[${index}][phone]"  onChange="${()=> console.log('test')}" />
                
                <${FieldContainer} columns="2" mb="" mt="">
            	<${InputField} value="${row.date_from}"   name="previos_employer[${index}][date_from]" label="Employed From" placeholder="Select Employed From"  datepicker="true"  onChange="${(e)=> updatePrevEmployerFrom(index, { date_from: moment(e.target.value)})}" /> 

            	<${InputField} value="${row.date_to}" label="Employed Until" placeholder="Select Employed Until" datepicker="true"  name="previos_employer[${index}][date_to]" onChange="${(e)=> updatePrevEmployerFrom(index, { date_to: moment(e.target.value)})}" />
            	</${FieldContainer}>

            </${FieldContainer}>
            
            

           

            <${FieldContainer} columns="2"> 
                <${InputField} label="Position"  placeholder="Enter Poisition" value="${row.position}"  name="previos_employer[${index}][position]" onChange="${()=> console.log('test')}" />
                
                <${FieldContainer} columns="2" mt='' mb=''>
                    <${InputField} type="number"  label="# of Accidents" placeholder="" value="${row.no_of_accidents}" name="previos_employer[${index}][no_of_accidents]"  onChange="${()=> console.log('test')}" /> 


            
            	
            	
            	<div>    
            	<label for="P0-17232323" class="block text-gray-900 text-sm leading-6 mb-[2px]">States drive in</label>
            	
            	<select placeholder="Select States" ref="${multidropdown}" class="multiple-select full-width"  name="previos_employer[${index}][states_drive_in][]" multiple="multiple" data-width="200">
            	
                </select>
                
                </div>
            	
            	
                </${FieldContainer}>
            	

            </${FieldContainer}>
            
             <${FieldContainer} columns="1">

            	

            	<${InputTextarea} value="${row.reason_for_leaving}" label="Reason for leaving" placeholder="Enter reason for leaving..."  name="previos_employer[${index}][reason_for_leaving]"  onChange="${()=> console.log('test')}" />

            </${FieldContainer}>
            
            <${FieldContainer} columns="1">

            	<${InputOptions} value="${row.has_safety_regulations}"  type="radio" label="Were you subject to Federal Motor Carrier Safety Regulations?"  name="previos_employer[${index}][has_safety_regulations]" onInput="${() => console.log(1234)}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />

            </${FieldContainer}>
            
            <${FieldContainer} columns="1">

            	<${InputOptions} value="${row.has_drug_testing}" type="radio" label="Were you subject to DOT related drug & alcohol testing?"   name="previos_employer[${index}][has_drug_testing]" onChange="${() => console.log(1234)}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />

            </${FieldContainer}>
            
    `;
    
}

function EmployerSection(props)
{
	
	const [validationProgress,setValidationProgress] = useState(0);
	
	
	useEffect( ()=> {

         // prefill address section
          if( props.data && props.data.value && props.data.value.employers )
          {
              previous_employers.value = props.data.value.employers;
              
              console.log("EmployerSection", previous_employers.value);
          }
        
    }, [props.data.value] );
	
	return html`
		<div>
			
            ${ allowAddPreviousEmployer.value && shouldAddPreviousEmployer() && html `<${NewEmployerFields} />`}
            ${ previous_employers.value.length > 0 && html`<${Fragment}><h3 class="mt-5 text-sm font-semibold leading-7 " style="display:none;">Previous Employers</h3> <${SubHeading} text="PREVIOUS EMPLOYERS" />
            <div class="bg-[#f3f3f3]  rounded-md border-black border-5 shadow-1">
                <${AccordionGroup}>
                ${previous_employers.value.map( (row ,index, arr) => ( html ` 
                <${Accordion} onRemove="${ (e) => removePrevEmployer(row) }" first="${index === 0}" last="${index === arr.length - 1}" heading="${row.employer_name  + ' ' +( row.supervisor_name )+ ' - ' +( moment(row.date_to).diff(moment(row.date_from), 'years', true)).toFixed(1) + ' Years'}">
                        <${PreviousEmployerRow} row="${row}" index="${index}" />
                    </${Accordion}>`) ) }
                </${AccordionGroup}>
                </div></${Fragment}>` }
            
            

		</div>
	`;
}

function NewEmployerFields()
{
    const multidropdown = useRef(null);
    
    const error = useSignal(false);
    
     const getStates = () => (this.context.store.getState().states); 
    
    useEffect( () => {
        
       const s =  $(multidropdown.current).selectize({
             valueField: 'value',
              labelField: 'label',
              searchField: 'label',
              
             options: getStates(),
              plugins: ["remove_button"],
              delimiter: ","
        });
       
        
    }, [] );
    
    return html `
    
        <${Fragment}>
        <${SubHeading} text="ADD EMPLOYMENT HISTORY" />
        
        <${FieldContainer} columns="2">
                <${InputField} label="Employer Name" id="currentEmployerName" placeholder="Enter Employer Name" name="employer_name" />
                
                <${InputField} label="Supervisor Name" id="currentEmployerSupervisorName" placeholder="Enter Supervisor Name" name="supervisor_name" />
            </${FieldContainer}>
            

            <${FieldContainer} columns="1">

            	<${InputField} label="Address" id="EmployerAddress" placeholder="Enter address" name="address" onChange="${()=> console.log('test')}" />

            	

            </${FieldContainer}>

            <${FieldContainer} columns="2">
                <${PhoneField}  label="Phone" placeholder="Enter Phone" name="phone" id="EmployerPhone" onChange="${()=> console.log('test')}" />
                
                <${FieldContainer} columns="2" mb="" mt="">
            	<${InputField}  id="employedFrom" label="Employed From" placeholder="Select Employed From" name="date_from"  datepicker="true"  onChange="${()=> console.log('test')}" /> 

            	<${InputField} id="employedUntil" label="Employed Until" placeholder="Select Employed Until" datepicker="true" name="date_to" onChange="${()=> console.log('test')}" />
            	</${FieldContainer}>

            </${FieldContainer}>
            
            

           

            <${FieldContainer} columns="2">
                <${InputField} label="Position" id="EmployerPostion" placeholder="Enter Poisition" name="position" onChange="${()=> console.log('test')}" />
                
                <${FieldContainer} columns="2" mt='' mb=''>
                    <${InputField} type="number" id="EmployerNoOfAccidents" label="# of Accidents" placeholder="" name="no_of_accidents" onChange="${()=> console.log('test')}" />
                    
                    <div>    
            	<label for="P0-1722232323" class="block text-gray-900 text-sm leading-6 mb-[2px]">States drive in</label>
            	
            	<select placeholder="Select States" id="EmployerStateDriveIn"  ref="${multidropdown}" class="multiple-select full-width" name="states_drive_in" multiple="multiple" data-width="200">
            	
                </select>
                
                </div>


            
                
                </${FieldContainer}>
            	

            </${FieldContainer}>
            
             <${FieldContainer} columns="1">

            	

            	<${InputTextarea}  label="Reason for leaving" placeholder="Enter reason for leaving..." id="EmployerReasonLeaving" name="reason_for_leaving" onChange="${()=> console.log('test')}" />

            </${FieldContainer}>
            
            <${FieldContainer} columns="1">

            	<${InputOptions} type="radio" label="Were you subject to Federal Motor Carrier Safety Regulations?" name="has_safety_regulations" id="EmployerHasSafetyReg" onChange="${() => console.log(1234)}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />

            </${FieldContainer}> 
            
            <${FieldContainer} columns="1">

            	<${InputOptions} type="radio" label="Were you subject to DOT related drug & alcohol testing?" id="EmployerHasDrugTesting" name="has_drug_testing" onChange="${() => console.log(1234)}" options="${ [{label: 'Yes', value: '1'}, {label: 'No', value: '0'}] }"  />

            </${FieldContainer}>
            
            <${EmployerInfoRequired} msg="${error}" />
            
            <div class="text-right">
                ${ allowAddPreviousEmployer.value && shouldAddPreviousEmployer() && html `                    
    
                    <a href="javascript:void(0);" data-native class="inline-block mt-2 text-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 " onClick="${(e) => addMoreEmployer(e, error)}">+ Add </a>
                    
                ` }
            </div>
            </${Fragment}>
            
    `;
}

function EmployerInfoRequired({msg})
{
    return msg.value ?  html `<div class="mt-2"><${AlertError} msg="${msg.value}" /></div>` : '';
}

function Form({ data }){
    return html `
    <div>
         <div class="py-3"><${AlertInfo} msg="10 Years history must be complete" /></div>
        <${EmployerSection} data="${data}" />
    </div>
    
    `;
}

function FormSubmitError({msg})
{
    return  allowAddPreviousEmployer.value && shouldAddPreviousEmployer() &&  msg.value ? html `<div class="mt-2"><${AlertError} msg="${msg.value}" /></div>` : '';
}

function FormContainer({ currentStep, data, _validate })
{
    const f_ref = useRef(null);
    
    const totalyears = getSumOfDateDiff(previous_employers.value);
        
    
    
    const handleValidation = () => {
        
        let formIsValid = previous_employers.value.length > 0;
        
        return formIsValid;
    }
    
    const onSubmit = (e) => {
        e.preventDefault();
        
        if( (previous_employers.value.length == 0) )
        {
            
             errormessage.value = 'Click "Add Employer" button before clicking "Save & Continue" button';
             
            return false;
        }else if( totalyears < 10 )
        {
             
             errormessage.value = 'Employment duration must be 10 years, continue adding employer history until 10 years period is covered.';
            return false;
        }
        
         if(!handleValidation()){
             
              //alert('employer information required!');
              //return false; 
         }else {
            
            submitLoader.value = true;
            const formdata = new FormData(e.target);
            const url = '/applyemployment/save'; 
            fetch(url, {
                method: 'post',
                body: formdata
            }).then(r => r.json()).then(r => {
                if( r.success )
                {
                    //alert('sucess');
                    _validate(3);
                    console.log(r);
                    route(r.redirect);
                    
                   // location = r.redirect;
                }else {
                    alert('error');
                }
                submitLoader.value = false;
            }).catch(e => {
                alert('error');
                submitLoader.value = false;
            });
        }
        
        
        
    };
    
    const _prev = () => {
        const backstepto = '1';
        const path = getCurrentUrl().split('/'); 
        path[path.length-1] = backstepto;
        route(path.join('/'));
    }
    
    useEffect( () => {
       
    }, [] );
    
    return html `
    <div>
         <form method="post" onSubmit="${onSubmit}" ref="${f_ref}" class="text-left" >
            <input type="hidden" name="_token" value="${csrf_token}" />
            <input type="hidden" name="driver_id" value="${data.value && data.value.driver_id}" />
            <${Form} data="${data}" />
            

            <${FormSubmitError} msg="${errormessage}" />
            
            <div class="flex items-center justify-between mt-8">
                 <button type="button" onClick="${(e) => _prev()}" class="flex items-center text-gray-700 text-sm font-medium rounded hover:underline focus:outline-none">
                    <svg class="h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
                    <span class="mx-2">Back</span>
                </button>
                <button type="submit" class="flex items-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 ${submitLoader.value ? 'disabled' : ''}">${submitLoader.value ? html `<${Fragment}><svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg> Processing </${Fragment}>` : html`<${Fragment}><span>Save & Continue</span>
<svg class="h-5 w-5 mx-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg></${Fragment}>`}</button>
            </div>
         </form>
         <${SignModalComponent} />
         
    </div>`;
}


export default function DriverEmployerInformation({ data, currentStep, afterValid })
{
    const _validate =(s) => {
        
       // step1progress.value = 20;
        
    	afterValid(s);
  	}
  	
    return html `<${FormContainer} currentStep="${currentStep}" data="${data}" _validate="${_validate}" />`;
} 