import { render, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { html } from 'htm/preact';
import { signal, useSignal } from "@preact/signals";
import moment from 'moment';
import IMask from 'imask';


import {InputOptions, FormControlSelect, InputField, InputTextarea, FieldContainer, FieldColumn, FieldLabel, PhoneField} from '../components/FormControls.js';

import {SubHeading} from '../components/Heading.js'; 

const submitLoader = signal(false);
const openForm = signal(true);
const step = signal(1);

const controlcontainercss = 'mb-[20px] flex justify-between flex-col sm:flex-row';


function QuestionaireSubSection()
{
    
    const checked = useSignal(false);
    
    const onq3change = (e) => {
        
        checked.value = (e.target.value =='1');
    }
    
    console.log("QuestionaireSubSection re-renders")
    
    return html `
    <${Fragment}>
    <div class="${controlcontainercss}">
        <label for="qusOne" class="formbold-form-label text-gray-900">
          Any positive drug or alcohol tests?
        </label>

        <div class="formbold-radio-flex">
          <div class="">
            <label class="formbold-radio-label">
              <input onClick="${onq3change}" required value="1"
                class="w-4 h-4 text-blue-600 bg-white border-black-300 focus:ring-blue-500  focus:ring-2  dark:border-gray-500 mr-2"
                type="radio"
                name="has_drug_alcohol_test"
                id="qusOne1442"
              />
              Yes
            </label>
          </div>

          <div class="">
            <label  class="formbold-radio-label  pl-0">
              <input required value="0" 
                class="w-4 h-4 text-blue-600 bg-white border-black-300 focus:ring-blue-500  focus:ring-2  mr-2"
                type="radio" onClick="${onq3change}"
                name="has_drug_alcohol_test"
                id="qusOne111"
              />
              No
            </label>
          </div>

          
        </div>
      </div>

    ${checked.value ? html `
        <div class="${controlcontainercss}">
        <label for="qusOne" class="formbold-form-label text-gray-900">
          Have you completed the substance abuse program (SAP)?
        </label>

        <div class="formbold-radio-flex">
          <div class="">
            <label class="">
              <input required value="1"
                class="w-4 h-4 text-blue-600 bg-white border-black-300 focus:ring-blue-500  focus:ring-2 mr-2"
                type="radio"
                name="has_completed_sap"
                id="qusOne14422"
              />
              Yes
            </label>
          </div>

          <div class="">
            <label class="">
              <input required value="0" 
                class="w-4 h-4 text-blue-600 bg-white border-black-300 focus:ring-blue-500  focus:ring-2  mr-2"
                type="radio"
                name="has_completed_sap"
                id="qusOne1113"
              />
              No
            </label>
          </div>

          
        </div>
      </div>
    
    ` : html `<input type="hidden" value="0" name="has_completed_sap" />`}</${Fragment}>`;
}

function QuestionaireSection(props)
{
    const homephoneref =  useRef(null);
    const phoneref = useRef(null);
    
   
     useEffect(()=>{
         
         const maskOptions = {
          mask: '000-000-0000'
        };
        const mask = IMask(phoneref.current, maskOptions);
        const mask2 = IMask(homephoneref.current, maskOptions);
         
     }, []);
    
    const handleChange = (e) => {
        
    }
    
    console.log("QuestionaireSection renders");
    
    
    return html `
        <div class="mb-5">
        <div class="${controlcontainercss}">
          <label for="cdl_license_duration" class="formbold-form-label text-gray-900">
            How long you have had your commercial driver license (CDL)?
          </label>
          <select style="width:auto;" name="cdl_license_duration" class="formbold-form-select" required>
            <option value="">Please Select</option>
            <option value="1">1 Year</option>
            <option value="2">2 Years</option>
            <option value="3">3 Years</option>
            <option value="4">4 Years</option>
            <option value="5">5 Years</option>
          </select>
        </div>
        
      </div>

      <div class="${controlcontainercss}">
        <label for="qusOne" class="formbold-form-label text-gray-900 ">
          Have you had any tickets or accidents in the last 3 years?
        </label>

        <div class="formbold-radio-flex">
          <div class="">
            <label class="formbold-radio-label ">
              <input required value="1"
                class="w-4 h-4 text-blue-600 bg-white border-black-300 focus:ring-blue-500  focus:ring-2 mr-2"
                type="radio"
                name="has_previous_tickets"
                id="qusOne1"
              />
              Yes
            </label>
          </div>

          <div class="">
            <label class="formbold-radio-label pl-0">
              <input required value="0" 
                class="w-4 h-4 text-blue-600 bg-white border-black-300 focus:ring-blue-500  focus:ring-2  mr-2"
                type="radio"
                name="has_previous_tickets"
                id="qusOne212"
              />
              No
            </label>
          </div>

          
        </div>
      </div>

      <${QuestionaireSubSection} />
      
        
        <div class="flex flex-wrap justify-between">
        <label for="qusOne" class="formbold-form-label text-gray-900 ">
          Are you interested in being an owner operator or a company driver?
        </label>

        <div class="formbold-radio-flex ml-0">
          <div class="">
            <label class="">
              <input required value="owner_operator"
                class="w-4 h-4 text-blue-600 bg-white border-black-300 focus:ring-blue-500   focus:ring-2  mr-2"
                type="radio"
                name="type"
                id="qusOne1466"
              />
              Owner Operator
            </label>
          </div>

          <div class="">
            <label class="formbold-radio-label">
              <input required value="company_driver" 
                class="w-4 h-4 text-blue-600 bg-white border-black-300 focus:ring-blue-500  focus:ring-2  dark:border-gray-500 mr-2"
                type="radio"
                name="type"
                id="qusOne55"
              />
              Company Driver
            </label>
          </div>

          
        </div>
      </div>
        
      <div class="mt-5 flex flex-wrap justify-between">
        <label for="qusOne" class="formbold-form-label text-gray-900 ">
          Are you interested in working part time or full time ?
        </label>

        <div class="formbold-radio-flex ml-0">
          <div class="">
            <label class="">
              <input required value="parttime"
                class="w-4 h-4 text-blue-600 bg-white border-black-300 focus:ring-blue-500   focus:ring-2  mr-2"
                type="radio"
                name="job_type"
                id="qusOne141r66"
              />
              Part Time
            </label>
          </div>

          <div class="">
            <label class="formbold-radio-label">
              <input required value="fulltime" 
                class="w-4 h-4 text-blue-600 bg-white border-black-300 focus:ring-blue-500  focus:ring-2  dark:border-gray-500 mr-2"
                type="radio"
                name="job_type"
                id="qusOnsrre55"
              />
              Full Time
            </label>
          </div>

          
        </div>
      </div>
      

<div class="flex flex-items flex-wrap items-center justify-between gap-x-4 gap-y-4 text-left mt-6">
    
    <div class="flex basis-full sm:basis-0 md:basis-0">
        <label class="block font-semibold">Name</label>
    </div>
	<div class="flex flex-wrap items-center gap-x-4 gap-y-8 text-left">
	<div>
		<input type="text" placeholder="First" required name="firstname" id="firstnamefield"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#8b8b8b] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
	</div>

	<div>
		<input type="text"  placeholder="Middle (Optional)"  name="middlename" id="middlenamefield"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#8b8b8b] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
	</div>

	<div>
		
		<input type="text" placeholder="Last" required name="lastname" id="lastnamefield" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#8b8b8b] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
	</div>
	</div>

</div>

       
<div class="flex flex-items flex-wrap items-center justify-between gap-x-4 gap-y-4 text-left mt-6">
    
    <div class="flex basis-full sm:basis-0 md:basis-0">
        <label class="block font-semibold">Contact</label>
    </div>
    <div class="flex flex-wrap items-center gap-x-4 gap-y-8 text-left">
    	<div>
    		<input type="text"  ref="${phoneref}" placeholder="Cell Phone"  required name="contact_cell_phone" id="cellphonefield"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#8b8b8b] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
    	</div>
    	<div>
    		<input type="text" ref="${homephoneref}"  placeholder="Home Phone (Optional)"  name="contact_home_phone" id="homephonefield"     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#8b8b8b] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
    	</div>
    	<div>
    		<input type="email" placeholder="Email Address" required name="contact_email_address" id="email-addrees" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#8b8b8b] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
    	</div>

    </div>
</div>
    `;
}

function Form({prev})
{
    
    const datesigned = useRef(null);
    
    useEffect( ()=>{
        
         
        
    }, [] );

    return html`
    <${Fragment}>
         <${QuestionaireSection} />
       
       
     
        <div class="flex items-center justify-between mt-8">
        <button type="button" onClick="${(e) => prev()}" class="flex items-center text-gray-700 text-sm font-medium rounded hover:underline focus:outline-none">
        <svg class="h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
        <span class="mx-2">Back</span>
    </button>
            <button type="submit" class="flex items center rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ${submitLoader.value ? 'disabled' : ''}">${submitLoader.value ? html `<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg> Processing` : html`Proceed`}</button>
        </div>
        </${Fragment}>
    `;
}

function FormContainer({ prev })
{
    const f_ref = useRef(null);
    const onSubmit = (e) => {
        e.preventDefault();
        
        
        submitLoader.value = true;
        const formdata = new FormData(e.target);
        const url = '/apply/screeing'; 
        fetch(url, {
            method: 'post',
            body: formdata
        }).then(r => r.json()).then(r => {
            if( r.success )
            {
                //alert('sucess');
                location = r.redirect;
            }else {
                alert('error');
            }
            submitLoader.value = false;
        }).catch(e => {
            alert('error');
            submitLoader.value = false;
        });
    };
    
    useEffect( () => {
        /*$.validator.setDefaults({
            errorClass: "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400",
            wrapper: "p",
    		submitHandler: function(form, event) {
    			onSubmit(event);
    		}
    	});*/
        //$( f_ref.current ).validate();
        
    }, [] );
    
    return html `
    <div>
    <p class="mt-6 text-md leading-8 ">FILL IN ALL BLANKS AND PROVIDE ALL INFORMATION REQUESTED</p>
    <div class="formbold-main-wrapper">
  <!-- Author: FormBold Team -->
  <!-- Learn More: https://formbold.com -->
  <div class="formbold-form-wrapper"> 
    
         <form method="post" onSubmit="${onSubmit}" ref="${f_ref}" class="text-left" ><input type="hidden" name="_token" value="${csrf_token}" /><${Form} prev="${prev}" /></form>
         </div>
         </div>
         
    </div>`;
}

function successText(props){

return html `<div class="max-w-xs bg-white border border-gray-200 rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700" role="alert">
  <div class="flex p-4">
    <div class="flex-shrink-0">
      <svg class="flex-shrink-0 h-4 w-4 text-teal-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
      </svg>
    </div>
    <div class="ms-3">
      <p class="text-sm text-gray-700">
        ${props.message}
      </p>
    </div>
  </div>
</div>`;

}

function erorrText(props){

return html ` <div class="max-w-xs bg-white border border-gray-200 rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700" role="alert">
  <div class="flex p-4">
    <div class="flex-shrink-0">
      <svg class="flex-shrink-0 h-4 w-4 text-red-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
      </svg>
    </div>
    <div class="ms-3">
      <p class="text-sm text-gray-700">
        ${props.message}
      </p>
    </div>
  </div>
</div>`;

}

function checkIcon()
{
    return html `<svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>`;
}

function checkList({ items })
{
    return html `
    <ul class="space-y-4 text-left text-[14px] text-gray-900">

        ${items.map(item => (html `<li class="flex items-center space-x-3 rtl:space-x-reverse">
            <${checkIcon} />
            <span class="font-semibold text-gray-900">${item}</span>
        </li>`))}
    </ul>
    `;
}

function requirementSection({next, prev})
{
  return html`<div class="mt-10 text-left">
    

    <${SubHeading} text="REQUIREMENTS" /> 

    <p class="mb-3 text-gray-900 text-[14px] leading-5">Thank you for your interest in Miles Of Smiles Trucking. To apply for a driving position, please complete our online application for employment. Incomplete information will delay the processing of your application or prevent it from being submitted.</p>

<p class="mb-3 text-[14px] text-gray-900 leading-5">In compliance with Federal and State equal employment opportunity laws, qualified applicants are considered for all positions without regard to race, color, religion, sex, national origin, age, marital status, veteran status, non-job related disability, or any other protected group status.
</p>
<p class="mb-3 text-[14px] text-gray-900 leading-5">To fill out this form, you will need to know the following:</p>

<${checkList} items="${['Social Security Number', 'Home address history for the past 10 years', 'Current driver license number and driver license history for the past 5 years', 'Employment history up to 10 years', 'History of traffic accidents, violations and/or convictions from the last 5 years (including DUI or reckless driving conviction and license suspension)', 'Military history (if applicable)']}" />
<br />
<p class="mb-3 text-[14px] text-gray-900 leading-5">Required entry fields are followed by *, meaning you must provide the requested information to continue. If you encounter any errors during this process and cannot continue, please contact us at <a href="mailto:support@mostrucking.us">support@mostrucking.us</a>.</p>
  
  <div class="flex items-center justify-end mt-8">
     
    <button type="button"  onClick="${(e) => next()}" class="flex items-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500">Next</button>
</div></div>`;
}

function criteriaSection({next, prev})
{
  return html `<div class="mt-10 text-left text-[14px]">
    
    <!--<h4 class="text-xl font-bold  mb-4">Things you will need</h4> -->
    <${SubHeading} text="THINGS YOU WILL NEED" />

    <p class="mb-3 text-gray-900  leading-5">To qualify with Miles Of Smiles Trucking, you must meet the following criteria:</p>

<${checkList} items="${['Must have a valid CDL A', 'Must be at least 21 years of age', 'Must meet all DOT requirements and qualifications with an acceptable motor vehicle record', 'No recent, major accidents such as a rollover, jackknife, or hitting a low bridge']}" />
<br />
  
    <div class="flex items-center justify-between mt-8">
     <button type="button" onClick="${(e) => prev()}" class="flex items-center text-gray-700 text-sm font-medium rounded hover:underline focus:outline-none">
        <svg class="h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
        <span class="mx-2">Back</span>
    </button>
    <button type="button" onClick="${(e) => next()}" class="flex items-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500">Next</button>
</div>
  </div>`;
}

function scrollToTargetAdjusted(el){
    var element = el;
    var headerOffset = 150;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
}

function StepContainer()
{
  const _next = () => {
      
      setTimeout(() => {
      scrollToTargetAdjusted(document.getElementById("topformheading"));
      }, 400);
    step.value = step+1;
   

  }

  const _prev = () => {
      
      setTimeout(() => {
      scrollToTargetAdjusted(document.getElementById("topformheading"));
      }, 400);
    step.value = step-1;
    

  };
  
 

  return html `
    <${Fragment}>
      ${ step == '1' && html `<${requirementSection} next="${_next}" prev="${_prev}" />`}
      ${ step == '2' && html `<${criteriaSection} next="${_next}" prev="${_prev}" />`}
      ${ step == '3' && html `<${FormContainer} next="${_next}" prev="${_prev}" />`}
    </${Fragment}>
  `;
}

export default function ApplyScreening() {
	
    

	return html`<${Fragment}>
		
		   <${StepContainer} />
		
	 </${Fragment}>`;
}