import { html } from 'htm/preact';
import { useId, useRef, useEffect  } from 'preact/hooks';
import { Fragment, toChildArray } from 'preact';
import IMask from 'imask';


function InputInlineOptions({ type = 'radio', label='' , name="", id=useId(), onChange, options, required = false, value='', defaultval = false, labelSize = '' , inlinestyle = true, indent = ''})
{
    const radiostyle = inlinestyle ? 'flex' : '';
	const optionsarr = toChildArray(options);
	const checkboxclass = 'h-6 w-6 rounded border-gray-300 text-blue-600 focus:ring-blue-600';

    const radioclass = 'w-4 h-4 text-blue-600 bg-white border-gray-300 focus:ring-blue-500  focus:ring-2  mr-1';
    
    const inputstyleclass = type == 'radio' ? radioclass : checkboxclass;
    
	return html
`	<div class="${radiostyle}">
		<label for="${id}" class="${required && 'after:content-['*'] after:ml-0.5 after:text-red-500'} text-gray-900 text-sm mr-2 mb-0 ${indent}">${label}</label>
	<div class="${inlinestyle ? 'flex flex-end' : ' mt-[2px]'}" id="${id}">
		
		${optionsarr.map( o => ( html `<div class="${type == 'radio' ? 'formbold-radio-group' : '' } mr-3">
			<label class="${ type == 'radio' ? 'formbold-radio-label' : 'flex align-top h-6 items-center text-xl'}  ${labelSize}">
              <input checked="${value == o.value}" onClick="${onChange}" required="${required}" value="${o.value}"
                class="${inputstyleclass}"
                type="${type}"
                name="${name}"
                id="${useId()}"
              />
              
              ${type == 'radio' ? html `<${Fragment}>${o.label} </${Fragment}>` : html `<div class="ml-2">${o.label}</div>` }
            </label>
		</div>`)  )}
	</div>
	</div>`
}

function InputOptions({ type = 'radio', label='' , name="", id=useId(), onChange, options, required = false, value='', defaultval = false, labelSize = '' , inlinestyle = true})
{
    const radiostyle = inlinestyle ? 'flex justify-between flex-col sm:flex-row' : '';
	const optionsarr = toChildArray(options);
	const checkboxclass = 'h-6 w-6 rounded border-gray-300 text-blue-600 focus:ring-blue-600';

    const radioclass = 'w-4 h-4 text-blue-600 bg-white border-gray-300 focus:ring-blue-500  focus:ring-2 mr-1';
    
    const inputstyleclass = type == 'radio' ? radioclass : checkboxclass;
    
	return html
`	<div class="${radiostyle}">
		<label for="${id}" class="${required && 'after:content-['*'] after:ml-0.5 after:text-red-500'} text-gray-900 text-sm mr-2 mb-0">${label}</label>
	<div class="${inlinestyle ? 'flex flex-end' : ' mt-[2px]'}" id="${id}">
		
		${optionsarr.map( o => ( html `<div class="${type == 'radio' ? 'formbold-radio-group' : '' } mr-3">
			<label class="${ type == 'radio' ? 'formbold-radio-label' : 'flex align-top h-6 items-center text-xl'}  ${labelSize}">
              <input checked="${value == o.value}" onClick="${onChange}" required="${required}" value="${o.value}"
                class="${inputstyleclass}"
                type="${type}"
                name="${name}"
                id="${useId()}"
              />
              
              ${type == 'radio' ? html `<${Fragment}>${o.label} </${Fragment}>` : html `<div class="ml-2">${o.label}</div>` }
            </label>
		</div>`)  )}
	</div>
	</div>`
}

function InputOptionsList({ type = 'radio', label='' , name="", id=useId(), onChange, options, required = false, value='', defaultval = false, labelSize = '' , inlinestyle = true})
{
    const radiostyle = inlinestyle ? '' : '';
	const optionsarr = toChildArray(options);
	const checkboxclass = 'h-6 w-6 rounded border-gray-300 text-blue-600 focus:ring-blue-600';

    const radioclass = 'w-4 h-4 text-blue-600 bg-white border-gray-300 focus:ring-blue-500 focus:ring-2 mr-1';
    
    const inputstyleclass = type == 'radio' ? radioclass : checkboxclass;
    
	return html
`	<div class="${radiostyle}">
		<label for="${id}" class="${required && 'after:content-['*'] after:ml-0.5 after:text-red-500'} block text-sm mr-2 mb-0">${label}</label>
	<div class="${inlinestyle ? '' : ' mt-[2px]'} mt-1" id="${id}">
		
		${optionsarr.map( o => ( html `<div class="${type == 'radio' ? 'formbold-radio-group' : '' } mr-3 formbold-radio-group mr-3 mb-1">
			<label class="${ type == 'radio' ? 'w-full py-3 ms-2 text-sm font-medium text-gray-900' : 'flex align-top h-6 items-center text-xl'} pl-0 text-sm ${labelSize}">
              <input checked="${value == o.value}" onClick="${onChange}" required="${required}" value="${o.value}"
                class="${inputstyleclass}"
                type="${type}"
                name="${name}"
                id="${useId()}"
              />
              
              ${type == 'radio' ? html `<${Fragment}>${o.label}  </${Fragment}>` : html `<div class="ml-2">${o.label}</div>` }
            </label>
		</div>`)  )}
	</div>
	</div>`
}

function FormControlSelect({  label='' , name="", id=useId(), onChange, options, required = false, defaultval = false, style='' , wrapselect = false, additional_classes = '', emptyfirstoption = false })
{
	return html ` 
		<${Fragment}>

			<label for="${id}" class="${required && 'after:content-['*'] after:ml-0.5 after:text-red-500'} text-gray-900 text-sm">${label}</label>
			${ wrapselect ? html `<div class="mt-[2px]">
			    <select name="${name}" onChange="${onChange}" id="${id}" class="formbold-form-select py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 ${additional_classes}"  required="${required}" style="${style}">
			    ${ emptyfirstoption && html `<option value="">Please Select</option>` }
				${options.map( o => ( html `
						<option selected="${defaultval == o.value}" value="${o.value}">${o.label}</option>
						`)  )}
			</select>
			</div>` : html `
			    <select name="${name}" onChange="${onChange}" id="${id}" class="formbold-form-select py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 ${additional_classes}"  required="${required}" style="${style}">
			    ${ emptyfirstoption && html `<option value="">Please Select</option>` }
				${options.map( o => ( html `
						<option selected="${defaultval == o.value}" value="${o.value}">${o.label}</option>
						`)  )}
			</select>
			` }
			
		</${Fragment}>
	`;
}


function FormControlMultiSelect({ inputname, defaultval, values = [], placeholder='Select States', width=200 , onchange})
{
	const multidropdownref = useRef(null);
	useEffect(() => {

		const s =  $(multidropdownref.current).selectize({
	       valueField: 'value',
	        labelField: 'label',
	        searchField: 'label',
	        onChange : onchange,
	       options: values,
	        plugins: ["remove_button"],
	        delimiter: ",",
	      items: defaultval ? defaultval.split(',') : []
	  	});
	  	var control = s[0].selectize;

	  	if( defaultval && defaultval.split(',') )
	  	{
	  	    control.setValue(defaultval.split(','));
	  	    console.log(defaultval.split(','));
	  	}
	  	

	},[defaultval]);

	return html`<select placeholder="${placeholder}" ref="${multidropdownref}" value="${defaultval}" class="multiple-select full-width" name="${inputname}" multiple="multiple" data-width="${width}"></select>`;
}

function InputField({ type = 'text', label='' , placeholder="", name="", id=useId(), onChange, required=false, ref='', onLoad, datepicker=false, value='', defaultvalue='' })
{
    const ref_input = useRef(null);
    const enableDataPicker = (datepicker, el) => {
        const f = flatpickr(el,
        {dateFormat: 'Y-m-d', allowInput: true, altInput: true, altFormat: "m-d-Y"
            
        }); 
        if( f._input )
        {
            f._input.onkeypress = () => false;
        }
    }  
    
    const requiredconf = {
        labelclass : 'after:content-['*'] after:ml-0.5 after:text-red-500',
        inputclass : 'invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
    }
    
    
    useEffect( () => {
        
        
        if( datepicker )
        {
            //debugger;
            
            enableDataPicker(datepicker, ref_input.current);
        }

    }, [] );
    
	return html`
	<${Fragment}>
		<label for="${id}" class="block text-gray-900 text-sm leading-6 ${required ? requiredconf.labelclass : ''}">${label}</label>
	<div class="mt-[2px]">
		<input  type="${type}" value="${value}" defaultValue="${defaultvalue}" ref="${ref_input}" required="${required}" onInput="${onChange}" name="${name}" placeholder="${placeholder}" id="${id}" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 ${requiredconf ? requiredconf.inputclass : ''}" />

	</div>
	</${Fragment}>
`
}

function InputMonthYear({ type = 'text', label='' , placeholder="", name="", id=useId(), onChange, required=false, ref='', onLoad, datepicker=true, value='' })
{
    const ref_input = useRef(null);
    const enableDataPicker = (datepicker, el) => {
        const f = flatpickr(el,
        {
            // dateFormat: 'Y-m-d', allowInput: true, altInput: true, altFormat: "m-d-Y"
            
            plugins: [
                new monthSelectPlugin({
            shorthand: true,
                dateFormat: "m/Y",
                altFormat: "F Y"
                })
             ]
            
        }); 
        if( f._input )
        {
            f._input.onkeypress = () => false;
        }
    }  
    
    const requiredconf = {
        labelclass : 'after:content-['*'] after:ml-0.5 after:text-red-500',
        inputclass : 'invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
    }
    
    
    useEffect( () => {
        
        
        enableDataPicker(datepicker, ref_input.current);

    }, [] );
    
	return html`
	<${Fragment}>
		<label for="${id}" class="block text-gray-900 text-sm leading-6 ${required ? requiredconf.labelclass : ''}">${label}</label>
	<div class="mt-[2px]">
		<input  type="${type}" value="${value}" ref="${ref_input}" required="${required}" onInput="${onChange}" name="${name}" placeholder="${placeholder}" id="${id}" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 ${requiredconf ? requiredconf.inputclass : ''}" />

	</div>
	</${Fragment}>
`
}

function PhoneField({ name, label, onChange, placeholder, value, id })
{
    const ref_input = useRef(null);
   
    
    
    useEffect( () => {
        
        const maskOptions = {
          mask: '000-000-0000'
        };
        
        const mask = IMask($(ref_input.current).find('input').get(0), maskOptions);
        
        

    }, [] );
    //debugger;
	return html`
	<div ref="${ref_input}">
	    <${InputField} label="${label}" value="${value}" name="${name}" onChange="${onChange}" placeholder="${placeholder}" id="${id}" />
	</div>
`
}

function InputTextarea({  label='' , placeholder="", name="", id=useId(), onChange, rows='1', value='', required=false})
{
	return html`
	<${Fragment}>
		<label for="${id}" class="${required && 'after:content-['*'] after:ml-0.5 after:text-red-500'} block text-sm leading-6 ">${label}</label>
	<div class="mt-[2px]">
		<textarea value="${value}" required="${required}" rows="${rows}" onInput="${onChange}" name="${name}" placeholder="${placeholder}" id="${id}" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"></textarea>

	</div>
	</${Fragment}>
`
}

function FieldContainer({columns = 6, children = [], mt ='mt-5', mb = 'mb-5'})
{
    
	const no_of_columns = columns;
	const fields = toChildArray(children);
	const isFunction= (functionToCheck) =>  {
     return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }
    const hasnestedcontainer = fields.filter(l => (l.type == FieldContainer)).length;
    
    const getMobileCols = (c) =>
    {
        return 1;
    }
    
    let fieldwidth  = no_of_columns / fields.length;
    const mobilecolumns = getMobileCols(no_of_columns);

    const getsmColumns = (cols) => {
        let newcols = cols;
        switch(newcols){
            case '4':
                    newcols = 8;
                    fieldwidth = 2;
                    //mobilecolumns = 1;
                    break;
            case '6':
            case '2':
///newcols = 16;
                break;
        }
        return newcols;
    }
    
    const c = getsmColumns(no_of_columns);
    
  
	return html `<div class="mt-0 sm:${mt} grid  grid-cols-${mobilecolumns} sm:grid-cols-${(c)} sm:gap-x-6 gap-x-1 gap-y-2 sm:gap-y-8 text-left mb-2 sm:${mb}">
        ${ fields.length >0 &&  fields.map(f => ( html `<${FieldColumn} col="${fieldwidth}">${f}</${FieldColumn}>` )) }
	</div>`;
}


function FieldColumn(props)
{
    const col = props.col;
    const calc = parseInt(col);
	return html `
	    <div class="sm:col-span-${calc}">
	        
		    ${html `${props.children}`}
		    
		</div>
	`;
}


function FieldLabel(props)
{
	return html `<label for="${props.id}" class="block text-sm font-medium leading-6 ">${props.label}</label>`;
}


export {InputOptions, FormControlSelect, FormControlMultiSelect, InputField, InputTextarea, FieldContainer, FieldColumn, FieldLabel, PhoneField, InputOptionsList, InputInlineOptions, InputMonthYear };