import { render, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { html } from 'htm/preact';
import { signal } from "@preact/signals";

import moment from 'moment';
import IMask from 'imask';


import {InputOptions, FormControlSelect, InputField, InputTextarea, FieldContainer, FieldColumn, FieldLabel} from '../../components/FormControls.js';
import {AlertSuccess, AlertError, AlertInfo} from '../../components/Alert.js';
import {SubHeading} from '../../components/Heading.js';



import {Accordion, AccordionGroup} from '../../components/Accordion.js';

import { route } from 'preact-router';



const submitLoader = signal(false);

const errormessage = signal(false);

const openForm = signal(true);
const previous_addresses = signal([]);
const stayed_years = signal([]);
const allowAddPreviousAddress = signal(true);
const currentAddDateFrom = signal(null);
const currentAddDateTo = signal(null);

const country_states = signal([]);
 

const getCountryStateName = (arr, str) => {
	let res = arr.find(o => o.value == str);
	return res ? res.label : '';
}


const total_stayed_years = (arr) =>
{
    return arr.reduce((a, b) => a + b, 0);
 
} 

const shouldAddPreviousAddr = (currentAddDateFrom, currentAddDateTo, allowAddPreviousAddress) => {
    const from = moment(currentAddDateFrom.value);
    const to = moment(currentAddDateTo.value);
    let years = 0;
    if( from.isValid() && to.isValid() )
    {
        //debugger;
        const diff = to.diff(from, 'years');
        const stayed_yearsv = diff;
        years+=diff;
        
       // console.log((diff >= 3));
    }
    
    years += getSumOfDateDiff(previous_addresses.value);
    
    allowAddPreviousAddress.value = years < 3;
    return true;
}

const getSumOfDateDiff = (date_arr) =>
    {
        let total = 0;
        
        for (var i = 0; i < date_arr.length; i++) {
        	
        	if(date_arr[i].from && moment(date_arr[i].from).isValid() && date_arr[i].to && moment(date_arr[i].to).isValid())
        	{
        	    const a = moment(date_arr[i].from);
                const b = moment(date_arr[i].to);
                const diff = b.diff(a, 'years'); 
                
                total+= diff;
        	}
        }
        return total;
    }

function fieldError(props) {
	return html `<p class="mt-2 text-sm text-red-600">${props.msg}</p>`;

}
function addMoreAddress(errormessage, previous_addresses)
{
    
    errormessage.value = '';
    const totalyears = getSumOfDateDiff(previous_addresses.value);
    
    const address_fields = {address1: $("#crr-address1-field").val(), address2: $("#crr-address2-field").val(), city: $("#crr-city-field").val(), state: $("#crr-state-field").val(), zip: $("#crr-zip-field").val(), from: $("#crr-addr-datefrom-field").val(), to: $("#crr-addr-dateto-field").val()};
    

    let invalidfields = 0;
    
    for( let x in address_fields )
    {
        if( x != 'address2' )
        {
            invalidfields += address_fields[x] === '' ? 1 : 0;
        }
        
    }
    
    
    if( invalidfields > 0 ){
        
        errormessage.value = 'Please enter address information.';

        return false;
    }
    
    
    previous_addresses.value = [...previous_addresses.value, address_fields];
    
    
    // reset
    $("#crr-address1-field,#crr-address2-field,#crr-city-field,#crr-state-field,#crr-zip-field,#crr-addr-datefrom-field,#crr-addr-dateto-field").val(''), $('#crr-addr-datefrom-field,#crr-addr-dateto-field').next('input').val("");

}

function updatePrevAddressFrom(index,change , previous_addresses)
{
    
    previous_addresses.value = previous_addresses.value.map((addrss, i) => (i === index ? { ...addrss, ...change } : addrss));
}

function removePrevAddress(row, previous_addresses) {
    //debugger;
  previous_addresses.value = previous_addresses.value.filter(t => t !== row);
  shouldAddPreviousAddr(currentAddDateFrom, currentAddDateTo, allowAddPreviousAddress);
}


function Form({ data, errormessage })
{
    
    
    
    const getFormSectionField = (k) => {
        
        const info = data.value;
        if( info && info.hasOwnProperty(k) )
        {
            return info[k];
        }else {
            return '';
        }
    }
     
   
    return html`
    <div class="animated fadeIn mt-3"> 
    
        <div class="divide-y-3 divide-dashed mt-6">
            <div>
                <${GeneralSectionFields} data="${data}" />
            </div>
            <div>
                <${DriverLicenseFields} data="${data}"  />
            </div>
            <div>
                <${FirstClassCDA} data="${data}"  />
            </div>
            <div>
                <${AddressSection} data="${data}"  />
            </div>
            <div>
              <br />
        
        ${ allowAddPreviousAddress.value && shouldAddPreviousAddr(currentAddDateFrom, currentAddDateTo, allowAddPreviousAddress) &&  errormessage.value && html `<${AlertError} msg="${errormessage.value}" />`}
        
        <div class="flex items-center justify-end mt-8">
            <button type="submit" class="flex items-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 ${submitLoader.value ? 'disabled' : ''}">${submitLoader.value ? html `<${Fragment}><svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg> Processing </${Fragment}>` : html`<${Fragment}><span>Save & Continue</span>
<svg class="h-5 w-5 mx-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg></${Fragment}>`}</button>
        </div>  
            </div>
        
        </div>
        
        </div>
    `;
}



function PreviousAddressField(props)
{
    const row = props.row;
    const index = props.index;
    
    useEffect( () => {
        
        
        
    }, [] );
    
    const datefrom_ref = useRef(null);
    const dateto_ref = useRef(null);
    
    const onChange = (e) => {
        const extractname = e.target.name.slice(1,-1).split('][')[1];
        updatePrevAddressFrom(index, { [extractname]: e.target.value}, previous_addresses);
    }
        
        
    useEffect( ()=>{
        
        const f_from = flatpickr(datefrom_ref.current, {dateFormat: 'Y-m-d',allowInput: true, altInput: true, altFormat: "m-d-Y", onChange : function(d, str){
             updatePrevAddressFrom(index, { from: moment(str)}, previous_addresses);
             shouldAddPreviousAddr(currentAddDateFrom, currentAddDateTo, allowAddPreviousAddress);
        }});
        const f_to = flatpickr(dateto_ref.current, {dateFormat: 'Y-m-d',allowInput: true, altInput: true, altFormat: "m-d-Y", onChange : function(d, str){
            updatePrevAddressFrom(index, { to: moment(str)}, previous_addresses);
            shouldAddPreviousAddr(currentAddDateFrom, currentAddDateTo, allowAddPreviousAddress);
        }});
        
        f_from._input.onkeypress = () => false;
        f_to._input.onkeypress = () => false;
        
        
        
    }, [] );
    
    return html `
        <div>
            <div class="mt-2 grid grid-cols-1 gap-x-2.5 gap-y-2 sm:grid-cols-6 text-left relative">
                
                <div class="sm:col-span-3">
                  <label for="prev-address1-field${index}" class="block text-sm font-medium leading-6">Address Line 1</label>
                  <div class="mt-[2px]">
                    <input type="text" onInput="${ onChange }" name="previous_address[${index}][address1]" id="prev-address1-field${index}" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" value="${row.address1}" />
                  </div>
                </div>
                
               
                
                <div class="sm:col-span-3">
                  <label for="prev-address2-field${index}" class="block text-sm font-medium leading-6">Address Line 2 (Optional)</label>
                  <div class="mt-[2px]"> 
                    <input type="text"  onInput="${ onChange }" name="previous_address[${index}][address2]" id="prev-address2-field${index}" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" value="${row.address2}" />
                  </div>
                </div>
            </div>
            <div class="mt-2 grid grid-cols-1 gap-x-2.5 gap-y-2 sm:grid-cols-6 text-left">
        
                <div class="sm:col-span-1">
                  <label for="prev-city-field${index}" class="block text-sm font-medium leading-6">City</label>
                  <div class="mt-[2px]">
                    <input type="text"  onInput="${ onChange }" name="previous_address[${index}][city]" id="prev-city-field${index}"  class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" value="${row.city}" />
                  </div>
                </div>
        
                <div class="sm:col-span-1">
                  <${FormControlSelect} onInput="${ onChange }" name="previous_address[${index}][state]" id="prev-state-field${index}" emptyfirstoption="true" defaultval="${row.state}" label="State" wrapselect="true" options="${country_states.value}"   />
                </div>
                
             	<div class="sm:col-span-1">
                  <label for="prev-zip-field${index}" class="block text-sm font-medium leading-6 ">Zip</label>
                  <div class="mt-[2px]">
                    <input type="text"  onInput="${ onChange }" required name="previous_address[${index}][zip]" id="prev-zip-field${index}" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" value="${row.zip}" />
                  </div>
                </div>
                
                <div class="sm:col-span-1">
                  <label for="prev-addr-datefrom-field${index}" class="block text-sm font-medium leading-6 ">Date From</label>
                  <div class="mt-[2px]">
                    <input type="text"  required ref="${datefrom_ref}" name="previous_address[${index}][from]" id="prev-addr-datefrom-field${index}" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" value="${row.from}" />
                  </div>
                </div>
                <div class="sm:col-span-1">
                  <label for="prev-addr-dateto-field" class="block text-sm font-medium leading-6 ">Date To</label>
                  <div class="mt-[2px]">
                    <input type="text"   required ref="${dateto_ref}" name="previous_address[${index}][to]" id="prev-addr-dateto-field${index}" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" value="${row.to}" />
                  </div>
                </div>
                
            </div>
            
        </div>`;
}

function CurrentAddressFields({currentAddDateTo, currentAddDateFrom, allowAddPreviousAddress, previous_addresses, errormessage})
{
    const datefrom_ref = useRef(null);
    const dateto_ref = useRef(null);
    const address1Field = useRef(null);
    const address2Field = useRef(null);
    const postalField = useRef(null);
    const cityField = useRef(null);
    const stateField = useRef(null);
    
    useEffect( ()=>{
        
         const f_from = flatpickr(datefrom_ref.current, {dateFormat: 'Y-m-d',allowInput: true, altInput: true, altFormat: "m-d-Y", onChange : function(d, str){
             
             //currentAddDateFrom.value = moment(str);
             shouldAddPreviousAddr(currentAddDateFrom, currentAddDateTo, allowAddPreviousAddress);
        }});
        const f_to = flatpickr(dateto_ref.current, {dateFormat: 'Y-m-d',allowInput: true, altInput: true, altFormat: "m-d-Y", onChange : function(d, str){
            //currentAddDateTo.value = moment(str);
            shouldAddPreviousAddr(currentAddDateFrom, currentAddDateTo, allowAddPreviousAddress);
        }});
        
        f_from._input.onkeypress = () => false;
        
        f_to._input.onkeypress = () => false;
        
        /*initAutoCompleteAddress(address1Field.current, address2Field.current, postalField.current, cityField.current, stateField.current);*/
        
    }, [] );
    
    return html `
           <div>
            <div class="mt-2 grid grid-cols-1 gap-x-2.5 gap-y-2 sm:grid-cols-6 text-left">
            
                <div class="sm:col-span-3">
                  <label for="crr-address1-field" class="block text-sm font-medium leading-6">Address Line 1</label>
                  <div class="mt-[2px]">
                    <input type="text" ref="${address1Field}" name="current_address[address1]" id="crr-address1-field" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                
               
                
                <div class="sm:col-span-3">
                  <label for="crr-address2-field" class="block text-sm font-medium leading-6">Address Line 2 (Optional)</label>
                  <div class="mt-[2px]">
                    <input type="text" ref="${address2Field}" name="current_address[address2]" id="crr-address2-field" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
            </div>
            <div class="mt-2 grid grid-cols-1 gap-x-2.5 gap-y-2 sm:grid-cols-6 text-left">
        
                <div class="sm:col-span-1">
                  <label for="crr-city-field" class="block text-sm font-medium leading-6">City</label>
                  <div class="mt-[2px]">
                    <input type="text"  ref="${cityField}" name="current_address[city]" id="crr-city-field" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
        
                <div class="sm:col-span-1">
                  
                  
                  <${FormControlSelect} emptyfirstoption="true" id="crr-state-field" label="State" options="${country_states.value}"  wrapselect="true"  name="current_address[state]" />
                </div>
                
             	<div class="sm:col-span-1">
                  <label for="crr-zip-field" class="block text-sm font-medium leading-6 ">Zip</label>
                  <div class="mt-[2px]">
                    <input type="text" ref="${postalField}" name="current_address[zip]" id="crr-zip-field" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                
                <div class="sm:col-span-1">
                  <label for="crr-addr-datefrom-field" class="block text-sm font-medium leading-6 ">Date From</label>
                  <div class="mt-[2px]">
                    <input type="text" ref="${datefrom_ref}" name="current_address[from]" id="crr-addr-datefrom-field" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div class="sm:col-span-1">
                  <label for="crr-addr-dateto-field" class="block text-sm font-medium leading-6 ">Date To</label>
                  <div class="mt-[2px]">
                    <input type="text" ref="${dateto_ref}" name="current_address[to]" id="crr-addr-dateto-field" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                
                <div class="sm:col-span-1">
                <label for="crr-addr-dateto-fie222ld" class="block text-sm font-medium leading-6 invisible">Action</label>
                    ${ allowAddPreviousAddress.value && shouldAddPreviousAddr(currentAddDateFrom, currentAddDateTo, allowAddPreviousAddress) && html `
                    <a href="javascript:void(0);" class="inline-block mt-2 text-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 " onClick="${() => addMoreAddress(errormessage, previous_addresses)}">+ Add Address</a>
                    
                ` }
                </div>
                
            </div>
            
        </div>
        
       
    `;
}

function AddressSection(props)
{
    const getStates = () => (this.context.store.getState().states); 
    useEffect( ()=> {

         // prefill address section
          if( props.data && props.data.value && props.data.value.addresses )
          {
              previous_addresses.value = props.data.value.addresses;
              
              console.log("AddressSection", previous_addresses.value);
          }
        
    }, [props.data.value] );
    
    //console.log("AddressSection", props);
    
    
    
    return html `<div>
    <div><br /></div>
                <${SubHeading} mb="2" text="ADDRESS INFORMATION (PAST 3 YEARS)" />
                <div class="py-3"><${AlertInfo} msg="Note: If your current  address is less than 3 years, continue adding previous addresses to cover the previous 3 year period" /></div>
                
                <${CurrentAddressFields} currentAddDateTo="${currentAddDateTo}" currentAddDateFrom="${currentAddDateFrom}" allowAddPreviousAddress="${allowAddPreviousAddress}" previous_addresses="${previous_addresses}" errormessage="${errormessage}" />
                ${ previous_addresses.value.length > 0 && html`<${Fragment}><h3 class="text-sm font-semibold leading-7 invisible ">Previous Addresses</h3> 
                <div class="bg-[#f3f3f3]  rounded-md border-black border-5 shadow-1">
                    <${AccordionGroup}>
                    ${previous_addresses.value.map( (row ,index, arr) => ( html `<${Accordion} onRemove="${ (e) => removePrevAddress(row, previous_addresses) }" first="${index === 0}" last="${index === arr.length - 1}" heading="${row.address1 + (row.address2 ? row.address2 : '') + ' ' +( row.city )+ ' ' +( getCountryStateName(getStates(),row.state) )+ ' ' +( row.zip ) + ' - ' +( moment(row.to).diff(moment(row.from), 'years', true)).toFixed(1) + ' Years'}">
                            <${PreviousAddressField} row="${row}" index="${index}" />
                        </${Accordion}>`) ) }
                    </${AccordionGroup}>
                    </div></${Fragment}>` }
    </div>`;
}

function FirstClassCDA(props)

{
    
    const orig_date_issuedref = useRef(null);
    
    const getFCLassSectionField = (k) => {
        
        const info = props.data.value;
        if( info && info.hasOwnProperty(k) )
        {
            return info[k];
        }else {
            return '';
        }
    }
    
    useEffect( ()=>{
        
         const f = flatpickr(orig_date_issuedref.current, {dateFormat: 'Y-m-d',allowInput: true, altInput: true, altFormat: "m-d-Y"});
         f._input.onkeypress = () => false;

        
    }, [] );
     const prefill = props.data.value;
    
    return html ` <div class="mb-8">
    
    <${SubHeading} text="FRIST CDL CLASS A" /> 
    <div class="mt-2 grid grid-cols-1 gap-x-2.5 gap-y-2 sm:grid-cols-8 text-left">
    
        <div class="sm:col-span-2">
          
          <${FormControlSelect} wrapselect="true" emptyfirstoption="true" defaultval="${getFCLassSectionField('firstcdl_classa_state')}" required="true" id="o-state-cdl-issued-field" label="Original State CDL Issued" options="${country_states.value}" name="firstcdl_classa_state" />
        </div>
        
       
        
        <div class="sm:col-span-2">
          <label for="o-date-issued-field" class="block text-sm font-medium leading-6 ">Original Date Issued</label>
          <div class="mt-[2px]">
            <input type="text" placeholder="Enter Original Date Issued" ref="${orig_date_issuedref}" value="${getFCLassSectionField('firstcdl_classa_o_date')}" required name="firstcdl_classa_o_date" id="o-date-issued-field" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
          </div>
        </div>
        
    </div>
    
</div>`;
}

function DriverLicenseFields(props)
{
    const expiration_dateref = useRef(null);
    
    const getLicenseField = (k) => {
        
        const license = props.data.value && props.data.value.currentlicense ? props.data.value.currentlicense : '';
        if( license && license.hasOwnProperty(k) )
        {
            return license[k];
        }else {
            return '';
        }
    }
    
    useEffect( ()=>{
        
        
        const f = flatpickr( expiration_dateref.current, { defaultDate: getLicenseField('expiration_date'), dateFormat: 'Y-m-d',allowInput: true, altInput: true, altFormat: "m-d-Y"});
        f._input.onkeypress = () => false;

    }, [] );
    const prefill = props.data.value;
    
    
    
    
    return html `
        <div class="mb-8">
            
            <${SubHeading} text="CURRENT DRIVER'S LICENSE" />
            <div class="mt-2 grid grid-cols-1 gap-x-2.5 gap-y-2 sm:grid-cols-8 text-left">
            
                <div class="sm:col-span-2">
                  <label for="number-field" class="block text-sm font-medium leading-6 ">Number</label>
                  <div class="mt-[2px]">
                    <input type="text" value="${getLicenseField('license_number')}" required name="number" id="number-field" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                	
                <div class="sm:col-span-2">
                 
                  <${FormControlSelect} emptyfirstoption="true" defaultval="${getLicenseField('state')}" required="true" id="state-field" label="State"  wrapselect="true"  options="${country_states.value}"  name="state" />
                </div>
                <div class="sm:col-span-2">
                <${FormControlSelect} required defaultval="${getLicenseField('type')}" wrapselect="true" label="Type" name="license_type"  options="${ [{label: '', value: ''},{label: 'Class A', value: 'Class A'},{label: 'Class B', value: 'Class B'},{label: 'Class C', value: 'Class C'}] }" style='width:100%;' />
                </div>
                
                <div class="sm:col-span-2">
                  <label for="expiration-field" class="block text-sm font-medium leading-6 ">Expiration Date</label>
                  <div class="mt-[2px]">
                    <input type="text" value="${getLicenseField('expiration_date')}" required ref="${expiration_dateref}" placeholder="Enter expiration date..."  name="expiration_date" id="expiration-field" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                
            </div>
            
        </div>
    `;
}

function GeneralSectionFields({data})
{
    const [formValues, setFormValues] = useState({});
    
    const handleChange = (e) => {
    	//setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };
    
    const getGSectionField = (k) => {
        
        const info = data.value;
        if( info && info.hasOwnProperty(k) )
        {
            return info[k];
        }else {
            return '';
        }
    }

    const dobelemref = useRef(null);
    const phoneref = useRef(null);
    const homephoneref = useRef(null);
    
    const prefill = data.value;
    
    useEffect( ()=>{
        
        console.log( getGSectionField('dob'), 'DOB', data.value );
         const f = flatpickr(dobelemref.current, {dateFormat: 'Y-m-d',allowInput: true, altInput: true, altFormat: "m-d-Y"});
        f._input.onkeypress = () => false;
        
        const maskOptions = {
          mask: '000-000-0000'
        };
        const mask = IMask(phoneref.current, maskOptions);
        const mask2 = IMask(homephoneref.current, maskOptions);
            
        
    }, [] );
   
   useEffect( ()=>{
       setFormValues(prefill);
   }, [prefill]);
   
   /*useEffect( ()=>{
       
       console.log('GeneralSectionFields render', this);
   });*/

    
    return html `
        <${Fragment}>
        <div class="mb-8">
            <div>
            
               <${SubHeading} text="PERSONAL INFORMATION" />
                
                <ul class="items-center w-full text-sm font-medium rounded-lg sm:flex mb-0 sm:mb-4">
                    <li class="w-full mb-3 sm:mb-0">
                        <div class=" items-center ">
                             <label  class="block text-sm font-medium leading-6 ">First Name</label>
                            <input type="text" defaultValue="${getGSectionField('firstname')}" required onChange="${handleChange}"  name="firstname" placeholder="Enter First Name..." id="first-name-field" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 mt-[2px]" />
                            ${ formValues && formValues.hasOwnProperty('firstname') && formValues.firstname == '' &&  html `<${fieldError} msg="firstname is required" />` }
                        </div>
                    </li>
                    <li class="w-full mb-3 sm:mb-0">
                        <div class=" items-center ps-0 sm:ps-3">
                        <label  class="block text-sm font-medium leading-6 ">Middle Name</label>
                            <input type="text" defaultValue="${getGSectionField('middlename')}" name="middlename" onChange="${handleChange}"  placeholder="Enter Middle  Name..." id="middle-name-field" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6  mt-[2px]" />
                        </div> 
                    </li>
                    
                    <li class="w-full mb-3 sm:mb-0">
                        <div class=" items-center ps-0 sm:ps-3">
                        <label class="block text-sm font-medium leading-6 ">Last Name</label> 
                            <input type="text" name="lastname" defaultValue="${getGSectionField('lastname')}"  onChange="${handleChange}"  required placeholder="Enter Last Name..." id="last-name-field" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6  mt-[2px]" />
                        </div>
                    </li>
        
                </ul>
        
                <ul class="items-center w-full text-sm font-medium rounded-lg sm:flex">
                    <li class="w-full mb-3 sm:mb-0">
                        <div class="">
                            <label class="block text-sm font-medium leading-6 ">Cell Phone</label> 
                            <input type="text" ref="${phoneref}" placeholder="Cell Phone"  required name="cell_phone" defaultValue="${getGSectionField('cell_phone')}" id="first-name" onChange="${handleChange}"  autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 mt-[2px]" />
                        </div>
                    </li>
                    <li class="w-full mb-3 sm:mb-0">
                        <div class="ps-0 sm:ps-3">
                        <label class="block text-sm font-medium leading-6 ">Home Phone</label> 
                            <input type="text" ref="${homephoneref}" defaultValue="${getGSectionField('home_phone')}" placeholder="Home Phone" name="home_phone" id="last-name" onChange="${handleChange}"  autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6  mt-[2px]" />
                        </div>
                    </li>
                    
                    <li class="w-full mb-3 sm:mb-0">
                        <div class="ps-0 sm:ps-3">
                        <label class="block text-sm font-medium leading-6 ">Email Address</label> 
                            <input type="email" defaultValue="${getGSectionField('email')}"  placeholder="Email Address" required name="email" id="email-addrees" onChange="${handleChange}"  autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6  mt-[2px]" />
                        </div>
                    </li>
        
                </ul>
            </div>
            <div>
                <div class="mt-0 sm:mt-5 grid grid-cols-1 sm:gap-x-2.5 sm:gap-y-8 sm:grid-cols-8 text-left">
        
        
                    <div class="sm:col-span-2 mb-2 sm:mb-0">
                      <label for="dob" class="block text-sm font-medium leading-6 ">Date Of Birth</label>
                          <div class="mt-[2px]">
                            <input type="text" onChange="${handleChange}"  required ref="${dobelemref}" defaultValue="${getGSectionField('dob')}" name="dob" id="dob" autocomplete="family-name" placeholder="Enter date..." class="flatpickr block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
        
                    <div class="sm:col-span-2">
                          <label for="ssn" class="block text-sm font-medium leading-6 ">Social Security Number</label>
                          <div class="mt-[2px]">
                            <input type="text" required name="ssn" defaultValue="${getGSectionField('ssn')}" onChange="${handleChange}"  id="ssn" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </${Fragment}>
    `;
}

function FormContainer({ data, _validate, errormessage, previous_addresses })
{
    const f_ref = useRef(null);
    const onSubmit = (e) => {
        e.preventDefault();
        errormessage.value = '';
        
        const totalyears = getSumOfDateDiff(previous_addresses.value);
        
        if( (previous_addresses.value.length == 0) )
        {
            
             errormessage.value = 'Click "Add Address" button before clicking "Save & Continue" button';
            return false;
        }else if( totalyears < 3 )
        {
             
             errormessage.value = 'Address duration must be 3 years, continue adding addresses until 3 years period is covered.';
            return false;
        }
        
        submitLoader.value = true;
        const formdata = new FormData(e.target);
        const url = '/apply/save'; 
        fetch(url, {
            method: 'post',
            body: formdata
        }).then(r => r.json()).then(r => {
            if( r.success )
            {
                //alert('sucess');
                _validate(2);
                route(r.redirect);
                
               // location = r.redirect;
            }else {
                alert('error');
            }
            submitLoader.value = false;
        }).catch(e => {
            alert('error');
            submitLoader.value = false;
        });
    };
    
    useEffect( () => {
        /*$.validator.setDefaults({
            errorClass: "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400",
            wrapper: "p",
    		submitHandler: function(form, event) {
    			onSubmit(event);
    		}
    	});*/
        //$( f_ref.current ).validate();
        
        
        
    }, [] );
    
    return html `
    <div>
         <form method="post" onSubmit="${onSubmit}" ref="${f_ref}" class="text-left" >
            <input type="hidden" name="_token" value="${csrf_token}" />
            <input type="hidden" name="driver_id" value="${data.value && data.value.driver_id}" />
            <${Form} data="${data}" errormessage="${errormessage}"  />
            <div class="flex items-center justify-end mt-2">
                
                <button style="display:none;" type="button" onClick=${_validate} class="flex items-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500">
                    <span>Save & Continue</span>
                    <svg class="h-5 w-5 mx-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg>
                </button>
            </div>
         </form>
         
         
    </div>`;
}

export default function DriverGeneralInformation({ data, currentStep, afterValid , states})
{
    useEffect(()=>{
            
            country_states.value = states;
            
        },[states]);
        
    const _validate =(s) => {
        
       // step1progress.value = 20;
        
    	afterValid(s);
  	}
  	
    return html `<${FormContainer} data="${data}" previous_addresses="${previous_addresses}" errormessage="${errormessage}" _validate="${_validate}" />`;
} 