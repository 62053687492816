require('./bootstrap');

/*import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
*/

require('./route.js');
