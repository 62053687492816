import { Fragment, toChildArray } from 'preact';
import { html } from 'htm/preact';
import { useState } from 'preact/hooks';


function Accordion({ children, heading, onRemove, onToggle, last, first })
{
    
    const [show, setShow] = useState(false);
    
    const onClick = (e) => {
        
        setShow(!show);
        
        if(onToggle)
        {
           onToggle(e); 
        }
        
    }

    return html `
<${Fragment}>
  <h2  onClick="${onClick}" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border ${!last ? 'border-b-0' : ''} border-gray-200 ${first ? 'rounded-t-xl' : ''} focus:ring-4 focus:ring-gray-200  hover:bg-gray-100 gap-3" style="
    display: flex;
    align-items: baseline;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: row;
    justify-content: space-between;
">
    <button type="button" class="" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
      <svg data-accordion-icon class="w-3 h-3 ${show ? '': 'rotate-180'} shrink-0 mr-2 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
      </svg>
      <span>${heading}</span>
      
    </button>
    ${ onRemove && html `<span>
        <button type="button" style="color: red;" onClick="${onRemove}"><i class="fa-solid fa-times"></i></button>
    </span>`  }
    
  </h2>
  <div id="" class="${show ? '': 'hidden'}" aria-labelledby="accordion-collapse-heading-1">
    <div class="p-5 border border-b-0 border-gray-200 ">
      ${children}
    </div>
  </div>
  
</${Fragment}>

    `;
    
}

function AccordionGroup({ children, heading, onRemove, onToggle })
{
    
    const [show, setShow] = useState(false);
    
    const onClick = (e) => {
        
        setShow(!show);
        
        if(onToggle)
        {
           onToggle(e); 
        }
        
    }

    return html `
    <div id="accordion-collapse" data-accordion="collapse">
    
        ${children}

     
      
    </div>

    `;
    
}

export {Accordion, AccordionGroup};